import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { HznCurrencyInput, HznCurrencyInputHTML } from '@horizon/currency-input';
import { useEventListener } from '../../hooks/useEventListener';
import { AsCurrency } from '../../utils/Formatting';
import classNames from 'classnames';
import { InputErrorMessages, InputHandle } from './Input';

/**
 * Convert the string value of a HorizonCurrencyInput to a decimal.
 * @param value the raw input value
 * @returns a decimal representation of the value, or undefined if none provided.
 */
const ResolveHznCurrency = (value: string | null | undefined): number | undefined => {
    // catching for Horizon validation bug. allows currency input values $- and $+.
    // make sure that we either get $ or $[0-9].* .
    const regex = new RegExp('\\$[0-9].*');
    if (value == null || (value !== '$' && !regex.test(value))) return undefined;
    // transform "$123,456.78" => "123456.78", or empty string if null/undefined
    const decimalStr = (value ?? '').replace(/[\$,]/g, '');
    // transform "123456.78" => 123456.78, or undefined if empty string.
    return decimalStr === '' ? 0 : Number(decimalStr);
};

interface CurrencyInputProps extends Omit<HznCurrencyInputHTML, 'value'> {
    children: string;
    className?: string;
    value?: number;
    errorMessages?: InputErrorMessages;
    onValueChanged: (value: number | undefined) => any;
}

export const CurrencyInput = forwardRef<InputHandle, CurrencyInputProps>(
    ({ children: label, className, value, errorMessages, onValueChanged, ...attrs }, fwdRef) => {
        const inputRef = useRef<HznCurrencyInput>();

        // Set custom error messages, if any
        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.errorMessages = errorMessages;
            }
        }, [inputRef, errorMessages]);

        // if the value prop is meaningfully different than the currently shown input value,
        // reset the input value and check its validity
        useEffect(() => {
            if (inputRef.current && ResolveHznCurrency(inputRef.current.value) !== (value ?? 0)) {
                inputRef.current.value = value ? AsCurrency(value) : '$';
            }
        }, [inputRef, value]);

        // Callback to update the payment amount when the input value changes.
        const onChangeCallback = (input: HznCurrencyInput) => {
            const value = ResolveHznCurrency(input.value);
            if (input.validity.valid) {
                onValueChanged(value);
            }
        };

        // hook for external validation invocation
        useImperativeHandle(fwdRef, () => ({
            validate: () => {
                if (!inputRef.current) return false;
                inputRef.current.checkValidity();
                return inputRef.current.validity.valid;
            },
        }));

        // Update the payment amount after every keystroke
        useEventListener(inputRef, 'input', onChangeCallback);
        useEventListener(inputRef, 'change', onChangeCallback);
        useEventListener(inputRef, 'blur', onChangeCallback);
        //@ts-ignore 'clear' event is part of hzn-currency-input.
        useEventListener(inputRef, 'clear', onChangeCallback);

        return (
            <hzn-currency-input
                ref={inputRef}
                class={classNames(className, attrs.required || !!attrs.max || !!attrs.min ? 'validated-input' : undefined)}
                clearable
                pad-decimals
                name={label}
                display-name={label}
                {...attrs}
            >
                {label}
            </hzn-currency-input>
        );
    }
);
