import { emptySplitApi as api } from './RtkQueryClient-Empty';
export const addTagTypes = ['Baskets', 'CloudEvent', 'Orders', 'Users', 'Workflows', 'Workstations'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: build => ({
            getBasket: build.query<GetBasketApiResponse, GetBasketApiArg>({
                query: queryArg => ({ url: `/api/v1/baskets/${queryArg.basketId}` }),
                providesTags: ['Baskets'],
            }),
            getBasketSummaries: build.query<GetBasketSummariesApiResponse, GetBasketSummariesApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets`,
                    params: {
                        Term: queryArg.term,
                        BasketIds: queryArg.basketIds,
                        OwnerId: queryArg.ownerId,
                        SellingLocationId: queryArg.sellingLocationId,
                        SourceSystemId: queryArg.sourceSystemId,
                        Statuses: queryArg.statuses,
                        PaymentStates: queryArg.paymentStates,
                        SourceSystemIdType: queryArg.sourceSystemIdType,
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        SortField: queryArg.sortField,
                        SortDirection: queryArg.sortDirection,
                    },
                }),
                providesTags: ['Baskets'],
            }),
            getOrderTenderSummary: build.query<GetOrderTenderSummaryApiResponse, GetOrderTenderSummaryApiArg>({
                query: queryArg => ({ url: `/api/v1/baskets/${queryArg.basketId}/order-tender-summary` }),
                providesTags: ['Baskets'],
            }),
            getBasketPayments: build.query<GetBasketPaymentsApiResponse, GetBasketPaymentsApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments`,
                    params: {
                        PaymentStatuses: queryArg.paymentStatuses,
                        CheckNumbers: queryArg.checkNumbers,
                        PaymentTypes: queryArg.paymentTypes,
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        SortField: queryArg.sortField,
                        SortDirection: queryArg.sortDirection,
                    },
                }),
                providesTags: ['Baskets'],
            }),
            addBasketPayment: build.mutation<AddBasketPaymentApiResponse, AddBasketPaymentApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['Baskets'],
            }),
            handleBasketPaymentAction: build.mutation<
                HandleBasketPaymentActionApiResponse,
                HandleBasketPaymentActionApiArg
            >({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments/${queryArg.paymentId}`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['Baskets'],
            }),
            deletePayment: build.mutation<DeletePaymentApiResponse, DeletePaymentApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments/${queryArg.paymentId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Baskets'],
            }),
            updatePayment: build.mutation<UpdatePaymentApiResponse, UpdatePaymentApiArg>({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/payments/${queryArg.paymentId}`,
                    method: 'PATCH',
                    body: queryArg.body,
                }),
                invalidatesTags: ['Baskets'],
            }),
            getBasketWorkflowProgression: build.query<
                GetBasketWorkflowProgressionApiResponse,
                GetBasketWorkflowProgressionApiArg
            >({
                query: queryArg => ({ url: `/api/v1/baskets/${queryArg.basketId}/workflow` }),
                providesTags: ['Baskets'],
            }),
            sendUpdatedWorkflowProgression: build.mutation<
                SendUpdatedWorkflowProgressionApiResponse,
                SendUpdatedWorkflowProgressionApiArg
            >({
                query: queryArg => ({
                    url: `/api/v1/baskets/${queryArg.basketId}/workflow`,
                    method: 'POST',
                    body: queryArg.workflowProgression,
                }),
                invalidatesTags: ['Baskets'],
            }),
            options: build.mutation<OptionsApiResponse, OptionsApiArg>({
                query: () => ({ url: `/events`, method: 'OPTIONS' }),
                invalidatesTags: ['CloudEvent'],
            }),
            receiveCloudEvent: build.mutation<ReceiveCloudEventApiResponse, ReceiveCloudEventApiArg>({
                query: queryArg => ({ url: `/events`, method: 'POST', body: queryArg.body }),
                invalidatesTags: ['CloudEvent'],
            }),
            createOrders: build.mutation<CreateOrdersApiResponse, CreateOrdersApiArg>({
                query: queryArg => ({ url: `/api/v1/orders`, method: 'POST', body: queryArg.orderTenderRequestDto }),
                invalidatesTags: ['Orders'],
            }),
            getCheckoutAccess: build.query<GetCheckoutAccessApiResponse, GetCheckoutAccessApiArg>({
                query: queryArg => ({
                    url: `/api/v1/users/checkout-access`,
                    params: { storeLocationNumber: queryArg.storeLocationNumber },
                }),
                providesTags: ['Users'],
            }),
            getDocumentWorkflowStatus: build.query<
                GetDocumentWorkflowStatusApiResponse,
                GetDocumentWorkflowStatusApiArg
            >({
                query: queryArg => ({ url: `/api/v1/workflows/${queryArg.cartId}` }),
                providesTags: ['Workflows'],
            }),
            getWorkstationByNameAndLocation: build.query<
                GetWorkstationByNameAndLocationApiResponse,
                GetWorkstationByNameAndLocationApiArg
            >({
                query: queryArg => ({ url: `/api/v1/workstations/${queryArg.name}/${queryArg.storeLocationId}` }),
                providesTags: ['Workstations'],
            }),
            getWorkstationDrawerStatus: build.query<
                GetWorkstationDrawerStatusApiResponse,
                GetWorkstationDrawerStatusApiArg
            >({
                query: queryArg => ({
                    url: `/api/v1/workstations/${queryArg.workstationName}/${queryArg.storeLocationId}/drawer-status`,
                }),
                providesTags: ['Workstations'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as CheckoutApi };
export type GetBasketApiResponse = /** status 200 Details of the requested basket. */ BasketDto;
export type GetBasketApiArg = {
    /** The ID of the basket to look for. */
    basketId: string;
};
export type GetBasketSummariesApiResponse =
    /** status 200 A paginated list of basket summaries. */ BasketSummaryDtoPaginatedListDto;
export type GetBasketSummariesApiArg = {
    term?: string;
    basketIds?: string[];
    ownerId?: string;
    sellingLocationId?: string;
    sourceSystemId?: string;
    statuses?: BasketStatus[];
    paymentStates?: BasketPaymentState[];
    sourceSystemIdType?: SourceSystemIdType;
    pageNumber?: number;
    pageSize?: number;
    sortField?: string;
    sortDirection?: SearchSortDirection;
};
export type GetOrderTenderSummaryApiResponse =
    /** status 200 An order tender summary of the basket requested. */ OrderTenderSummaryDto;
export type GetOrderTenderSummaryApiArg = {
    /** The ID of the basket to the order tender summary from. */
    basketId: string;
};
export type GetBasketPaymentsApiResponse = /** status 200 The filtered payments. */ PaymentDtoPaginatedListDto;
export type GetBasketPaymentsApiArg = {
    /** The ID of the basket to obtain the payments from. */
    basketId: string;
    paymentStatuses?: PaymentStatus[];
    checkNumbers?: string[];
    paymentTypes?: PaymentType[];
    pageNumber?: number;
    pageSize?: number;
    sortField?: string;
    sortDirection?: SearchSortDirection;
};
export type AddBasketPaymentApiResponse = /** status 200 The created payment details. */
    | PaymentDto
    | AccountsPayablePaymentDto
    | AchPaymentDto
    | BankDraftPaymentDto
    | CashPaymentDto
    | CheckPaymentDto
    | DebitPaymentDto
    | CertifiedFundsPaymentDto
    | MailRefundCheckPaymentDto
    | RefundPaymentDto;
export type AddBasketPaymentApiArg = {
    /** The ID of the basket to add the payment too. */
    basketId: string;
    /** Request data containing payment details. */
    body:
        | CreateBasketPaymentRequestDto
        | CreateBasketCertifiedFundsPaymentRequestDto
        | CreateBasketCashPaymentRequestDto
        | CreateBasketCheckPaymentRequestDto
        | CreateBasketDebitPaymentRequestDto;
};
export type HandleBasketPaymentActionApiResponse = /** status 200 The payment after processing */
    | PaymentDto
    | AccountsPayablePaymentDto
    | AchPaymentDto
    | BankDraftPaymentDto
    | CashPaymentDto
    | CheckPaymentDto
    | DebitPaymentDto
    | CertifiedFundsPaymentDto
    | MailRefundCheckPaymentDto
    | RefundPaymentDto;
export type HandleBasketPaymentActionApiArg = {
    /** The ID of the basket where the payment is. */
    basketId: string;
    /** The ID of the payment to take action on. */
    paymentId: string;
    /** Request data containing payment details. */
    body:
        | BasketPaymentActionRequestDto
        | ProcessBasketPaymentActionRequestDto
        | VoidBasketPaymentActionRequestDto
        | RefundBasketPaymentActionRequestDto;
};
export type DeletePaymentApiResponse = /** status 204 No Content */ void;
export type DeletePaymentApiArg = {
    /** Basket ID */
    basketId: string;
    /** Payment ID */
    paymentId: string;
};
export type UpdatePaymentApiResponse = /** status 200 OK */
    | PaymentDto
    | AccountsPayablePaymentDto
    | AchPaymentDto
    | BankDraftPaymentDto
    | CashPaymentDto
    | CheckPaymentDto
    | DebitPaymentDto
    | CertifiedFundsPaymentDto
    | MailRefundCheckPaymentDto
    | RefundPaymentDto;
export type UpdatePaymentApiArg = {
    /** The Payment ID */
    paymentId: string;
    basketId: string;
    /** The updated Payment */
    body: UpdatePaymentRequest | UpdateCheckPaymentRequest;
};
export type GetBasketWorkflowProgressionApiResponse =
    /** status 200 The basket's workflow progression */ WorkflowProgression;
export type GetBasketWorkflowProgressionApiArg = {
    /** Basket id */
    basketId: string;
};
export type SendUpdatedWorkflowProgressionApiResponse = /** status 204 No Content */ void;
export type SendUpdatedWorkflowProgressionApiArg = {
    basketId: string;
    workflowProgression: WorkflowProgression;
};
export type OptionsApiResponse = unknown;
export type OptionsApiArg = void;
export type ReceiveCloudEventApiResponse = unknown;
export type ReceiveCloudEventApiArg = {
    body: any;
};
export type CreateOrdersApiResponse = /** status 200 The completed basket. */ BasketDto;
export type CreateOrdersApiArg = {
    /** Request data containing the basked ID that needs to be tendered. */
    orderTenderRequestDto: OrderTenderRequestDto;
};
export type GetCheckoutAccessApiResponse =
    /** status 200 The user's store access result for the given store */ StoreAccessResult;
export type GetCheckoutAccessApiArg = {
    /** The store location number to check CMXDB access to */
    storeLocationNumber?: number;
};
export type GetDocumentWorkflowStatusApiResponse = /** status 200 OK */ DocGenerationDto;
export type GetDocumentWorkflowStatusApiArg = {
    cartId: string;
};
export type GetWorkstationByNameAndLocationApiResponse =
    /** status 200 Details of the requested workstation. */ WorkstationSummaryDto;
export type GetWorkstationByNameAndLocationApiArg = {
    /** The name of the workstation to look for. */
    name: string;
    /** The ID of the store location the workstation belongs to. */
    storeLocationId: string;
};
export type GetWorkstationDrawerStatusApiResponse = /** status 200 OK */ WorkstationDrawerStatusSummaryDto;
export type GetWorkstationDrawerStatusApiArg = {
    /** The name of the workstation to look for. */
    workstationName: string;
    /** The ID of the store location the workstation belongs to. */
    storeLocationId: string;
};
export type BasketStatus = 'ACTIVE' | 'ABANDONED' | 'COMPLETED';
export type LineItemType =
    | 'ACCESSORY_ITEM'
    | 'APPRAISAL_ITEM'
    | 'AUCTION_ITEM'
    | 'CAF_ITEM'
    | 'OTHER_ITEM'
    | 'SERVICE_ITEM'
    | 'VEHICLE_SALE_ITEM'
    | 'GAP_SERVICE_ITEM'
    | 'ESP_SERVICE_ITEM';
export type PartyRole =
    | 'BUYER'
    | 'BUYER_REP'
    | 'CO_BUYER'
    | 'SELLER'
    | 'SELLER_REP'
    | 'TITLE_HOLDER'
    | 'LIEN_HOLDER'
    | 'ACTOR'
    | 'OWNER';
export type LineItemParticipantDto = {
    id?: string | null;
    name?: string | null;
    roles?: PartyRole[] | null;
};
export type BasketLineItemDto = {
    name?: string | null;
    lineItemType?: LineItemType;
    unitPrice?: number;
    quantity?: number;
    equity?: number | null;
    children?: BasketLineItemDto[] | null;
    participants?: LineItemParticipantDto[] | null;
};
export type PaymentDirection = 'INBOUND' | 'OUTBOUND';
export type MailRefundCheckPaymentDto = RefundPaymentDto & object;
export type RefundPaymentDto = {
    paymentType: 'RefundPaymentDto';
} & PaymentDtoBase &
    MailRefundCheckPaymentDto;
export type PaymentStatus =
    | 'NONE'
    | 'NEW'
    | 'AUTHORIZED'
    | 'PAID'
    | 'REFUNDED'
    | 'VOIDED'
    | 'CANCELLED'
    | 'DECLINED'
    | 'ERROR'
    | 'PROCESSING';
export type PaymentDtoBase = {
    paymentType: string;
    externalId?: string | null;
    basketId?: string | null;
    amount?: number;
    direction?: PaymentDirection;
    lineItemId?: string | null;
    lineOfBusinessProvided?: boolean;
    refundPayment?: (RefundPaymentDto | MailRefundCheckPaymentDto) | null;
    status?: PaymentStatus;
    id?: string | null;
};
export type AccountsPayablePaymentDto = {
    paymentType: 'ACCOUNTS_PAYABLE';
} & PaymentDtoBase &
    object;
export type AchPaymentDto = {
    paymentType: 'ACH';
} & PaymentDtoBase &
    object;
export type BankDraftPaymentDto = {
    paymentType: 'BANK_DRAFT';
} & PaymentDtoBase & {
        checkNumber?: string | null;
    };
export type CashPaymentDto = {
    paymentType: 'CASH';
} & PaymentDtoBase & {
        cashCollected?: number | null;
    };
export type CheckPaymentDto = {
    paymentType: 'CHECK';
} & PaymentDtoBase & {
        checkNumber?: string | null;
    };
export type DebitPaymentDto = {
    paymentType: 'DEBIT';
} & PaymentDtoBase & {
        cardSuffix?: string | null;
        expirationDate?: string | null;
    };
export type CertifiedFundsPaymentDto = {
    paymentType: 'CERTIFIED_FUNDS';
} & PaymentDtoBase & {
        bankName?: string | null;
        checkAmount?: number;
        checkNumber?: string | null;
    };
export type PaymentDto =
    | AccountsPayablePaymentDto
    | AchPaymentDto
    | BankDraftPaymentDto
    | CashPaymentDto
    | CheckPaymentDto
    | DebitPaymentDto
    | CertifiedFundsPaymentDto
    | MailRefundCheckPaymentDto
    | RefundPaymentDto;
export type BasketDto = {
    cartId?: string | null;
    basketOwnerName?: string | null;
    basketStatus?: BasketStatus;
    customerProcessedPaymentAmount?: number;
    lineItems?: BasketLineItemDto[] | null;
    payments?:
        | (
              | PaymentDto
              | AccountsPayablePaymentDto
              | AchPaymentDto
              | BankDraftPaymentDto
              | CashPaymentDto
              | CheckPaymentDto
              | DebitPaymentDto
              | CertifiedFundsPaymentDto
              | MailRefundCheckPaymentDto
              | RefundPaymentDto
          )[]
        | null;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type BasketPaymentState = 'NEW' | 'PARTIALLY_PAID' | 'PAID' | 'ERROR';
export type DecorationType =
    | 'UNKNOWN'
    | 'VIN'
    | 'ORIGINAL_PAYOFF_AMOUNT'
    | 'PATH_TITLE_ID'
    | 'VEHICLE_YEAR'
    | 'VEHICLE_MAKE'
    | 'VEHICLE_MODEL'
    | 'VEHICLE_TRIM'
    | 'VEHICLE_COLOR'
    | 'VEHICLE_COLOR_CODE'
    | 'VEHICLE_MILEAGE'
    | 'APPRAISAL_EQUITY';
export type DecoratorSummaryDto = {
    type?: DecorationType;
    values?: any[] | null;
};
export type BasketSummaryDto = {
    currencyCode?: string | null;
    sellingLocationId?: string | null;
    status?: BasketStatus;
    statusDate?: string | null;
    paymentState?: BasketPaymentState;
    inboundPaymentDue?: number;
    outboundPaymentDue?: number;
    customerName?: string | null;
    customerPhoneNumber?: string | null;
    equity?: number | null;
    decoratorsSummary?: DecoratorSummaryDto[] | null;
    createdOnUtc?: string;
    id?: string | null;
};
export type SearchSortDirection = 'ASC' | 'DESC';
export type BasketSummaryDtoPaginatedListDto = {
    results?: BasketSummaryDto[] | null;
    pageSize?: number;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
    sortField?: string | null;
    sortDirection?: SearchSortDirection;
    previousPage?: boolean;
    nextPage?: boolean;
};
export type SourceSystemIdType = 'NONE' | 'CART';
export type OrderTenderSummaryDto = {
    vehicleName?: string | null;
    vehicleOfferValue?: number | null;
    gapCancellationValue?: number;
    espCancellationValue?: number;
    payoffToLienholder?: number;
    lienholderName?: string | null;
    processedPaymentsTotal?: number;
    equityTotal?: number | null;
};
export type PaymentDtoPaginatedListDto = {
    results?:
        | (
              | PaymentDto
              | AccountsPayablePaymentDto
              | AchPaymentDto
              | BankDraftPaymentDto
              | CashPaymentDto
              | CheckPaymentDto
              | DebitPaymentDto
              | CertifiedFundsPaymentDto
              | MailRefundCheckPaymentDto
              | RefundPaymentDto
          )[]
        | null;
    pageSize?: number;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
    sortField?: string | null;
    sortDirection?: SearchSortDirection;
    previousPage?: boolean;
    nextPage?: boolean;
};
export type PaymentType =
    | 'ACCOUNTS_PAYABLE'
    | 'ACH'
    | 'BANK_DRAFT'
    | 'CASH'
    | 'CHECK'
    | 'DEBIT'
    | 'CERTIFIED_FUNDS'
    | 'MAIL_REFUND_CHECK';
export type ValidationProblemDetails = {
    errors?: {
        [key: string]: string[];
    } | null;
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type PartyRoleRequestDto = {
    partyId?: string | null;
    relationship?: PartyRole;
};
export type CreateBasketPaymentRequestDtoBase = {
    paymentType: string;
    amount?: number | null;
    direction?: PaymentDirection;
    lineOfBusinessProvided?: boolean | null;
    partyRole?: PartyRoleRequestDto;
};
export type CreateBasketCertifiedFundsPaymentRequestDto = {
    paymentType: 'CERTIFIED_FUNDS';
} & CreateBasketPaymentRequestDtoBase & {
        checkNumber?: string | null;
        bankName?: string | null;
    };
export type CreateBasketCashPaymentRequestDto = {
    paymentType: 'CASH';
} & CreateBasketPaymentRequestDtoBase &
    object;
export type CreateBasketCheckPaymentRequestDto = {
    paymentType: 'CHECK';
} & CreateBasketPaymentRequestDtoBase & {
        checkNumber?: string | null;
    };
export type CreateBasketDebitPaymentRequestDto = {
    paymentType: 'DEBIT';
} & CreateBasketPaymentRequestDtoBase &
    object;
export type CreateBasketPaymentRequestDto =
    | CreateBasketCertifiedFundsPaymentRequestDto
    | CreateBasketCashPaymentRequestDto
    | CreateBasketCheckPaymentRequestDto
    | CreateBasketDebitPaymentRequestDto;
export type BasketPaymentActionRequestDtoBase = {
    paymentAction: string;
    storeLocationId?: string | null;
    workstationName?: string | null;
    equipmentIdentifier?: string | null;
    startTime?: string;
    endTime?: string;
};
export type ProcessBasketPaymentDetailsDtoBase = {
    type: string;
};
export type ProcessBasketCashPaymentDetailsDto = {
    type: 'CASH';
} & ProcessBasketPaymentDetailsDtoBase & {
        cashCollected?: number;
    };
export type ProcessBasketCertifiedFundsPaymentDetailsDto = {
    type: 'CERTIFIED_FUNDS';
} & ProcessBasketPaymentDetailsDtoBase & {
        bankName?: string | null;
        checkAmount?: number;
        checkNumber?: string | null;
    };
export type ProcessBasketCheckPaymentDetailsDto = {
    type: 'CHECK';
} & ProcessBasketPaymentDetailsDtoBase & {
        checkNumber?: string | null;
    };
export type CardDetailsCollectionMethod = 'SWIPED' | 'MANUALLY_KEYED';
export type DebitCardPresentPaymentDetailsDto = {
    encryptedPin?: string | null;
    pinKey?: string | null;
    accountNumberSuffix?: string | null;
    expirationDate?: string | null;
    bin?: string | null;
    encryptedData?: string | null;
    keyData?: string | null;
    terminalId?: string | null;
    cardDetailsCollectionMethod?: CardDetailsCollectionMethod;
};
export type ProcessBasketDebitPaymentDetailsDto = {
    type: 'DEBIT';
} & ProcessBasketPaymentDetailsDtoBase & {
        debitCardPresentPaymentDetails?: DebitCardPresentPaymentDetailsDto;
    };
export type ProcessBasketPaymentDetailsDto =
    | ProcessBasketCashPaymentDetailsDto
    | ProcessBasketCertifiedFundsPaymentDetailsDto
    | ProcessBasketCheckPaymentDetailsDto
    | ProcessBasketDebitPaymentDetailsDto;
export type ProcessBasketPaymentActionRequestDto = {
    paymentAction: 'PROCESS';
} & BasketPaymentActionRequestDtoBase & {
        paymentDetails?:
            | (
                  | ProcessBasketPaymentDetailsDto
                  | ProcessBasketCashPaymentDetailsDto
                  | ProcessBasketCertifiedFundsPaymentDetailsDto
                  | ProcessBasketCheckPaymentDetailsDto
                  | ProcessBasketDebitPaymentDetailsDto
              )
            | null;
    };
export type VoidBasketPaymentActionRequestDto = {
    paymentAction: 'VOID';
} & BasketPaymentActionRequestDtoBase &
    object;
export type RefundBasketPaymentActionRequestDto = {
    paymentAction: 'REFUND';
} & BasketPaymentActionRequestDtoBase &
    object;
export type BasketPaymentActionRequestDto =
    | ProcessBasketPaymentActionRequestDto
    | VoidBasketPaymentActionRequestDto
    | RefundBasketPaymentActionRequestDto;
export type UpdatePaymentRequestBase = {
    paymentType: PaymentType;
    amount?: number | null;
};
export type UpdateCheckPaymentRequest = {
    paymentType: 'CHECK';
} & UpdatePaymentRequestBase & {
        checkNumber: string | null;
    };
export type UpdatePaymentRequest = UpdateCheckPaymentRequest;
export type WorkflowProgressionStepStatus = 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETE' | 'SKIPPED';
export type WorkflowProgressionStep = {
    stepName?: string | null;
    status?: WorkflowProgressionStepStatus;
    isEnabled?: boolean;
    remoteUrl?: string | null;
};
export type WorkflowProgression = {
    contractSessionId?: string | null;
    steps?: WorkflowProgressionStep[] | null;
};
export type OrderTenderRequestDto = {
    basketId: string;
};
export type StoreAccessResult = {
    userExists?: boolean;
    isClockedIn?: boolean;
};
export type DocGenerationDto = {
    documentsGenerationId?: string | null;
    documentsGenerationStatus?: string | null;
};
export type WorkstationType = 'STATIONARY' | 'MOBILE' | 'SAFEROOM';
export type WorkstationSummaryDto = {
    classicSystemsId?: number | null;
    isDrawerAssigned?: boolean | null;
    workstationType?: WorkstationType;
    storeLocationId?: string | null;
    name?: string | null;
    isDeleted?: boolean | null;
    deletedOnUtc?: string | null;
    deletedByUserId?: string | null;
    deletedByUserName?: string | null;
    createdByUserId?: string | null;
    createdByUserName?: string | null;
    createdOnUtc?: string | null;
    updatedByUserId?: string | null;
    updatedByUserName?: string | null;
    updatedUtc?: string | null;
    id?: string | null;
};
export type DrawerStatus = 'ASSIGNED' | 'UNASSIGNED' | 'CLOSED';
export type WorkstationDrawerStatusSummaryDto = {
    drawerName?: string | null;
    drawerStatus?: DrawerStatus;
};
export const {
    useGetBasketQuery,
    useGetBasketSummariesQuery,
    useGetOrderTenderSummaryQuery,
    useGetBasketPaymentsQuery,
    useAddBasketPaymentMutation,
    useHandleBasketPaymentActionMutation,
    useDeletePaymentMutation,
    useUpdatePaymentMutation,
    useGetBasketWorkflowProgressionQuery,
    useSendUpdatedWorkflowProgressionMutation,
    useOptionsMutation,
    useReceiveCloudEventMutation,
    useCreateOrdersMutation,
    useGetCheckoutAccessQuery,
    useGetDocumentWorkflowStatusQuery,
    useGetWorkstationByNameAndLocationQuery,
    useGetWorkstationDrawerStatusQuery,
} = injectedRtkApi;
