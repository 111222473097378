import React, { useEffect, useState } from 'react';
import { appIdentifierOAP, OAUTH_PROXY } from '../config';
import { OAPError } from '../models/OAPError';
import { getStatusCode, synthesizeErrorDetails } from '../helpers/ErrorParse';
import DOMPurify from 'dompurify';
import { FlexRow } from '../components/Flex';
import { handleTokenResponseData } from '@src/helpers/OAuthHelpers';

/**
 * This class is responsible for handling OAuth 2/OpenID Connect
 * redirect responses, which are either:
 * 1) Token responses, including either an access token, an ID token,
 * or both; or
 * 2) Logout responses
 *
 * For token responses, the ID token is verified if present, and its
 * claims are saved to session storage; the access token is also saved
 * to session storage, if present. The browser is then redirected to
 * the application's root for normal application processing.
 */
type OAuthCallbackProps = {
    onErrorChange: (error: OAPErrorPresentation) => void;
};

export type OAPErrorPresentation = {
    title: string;
    message: string;
    additionalDetails?: string;
    requestId?: string;
    statusNumber?: string;
    tone: 'critical' | 'caution' | 'info';
    action: () => void;
    actionLabel: string;
};

const validateRedirectUrl = (url: string) => (url.startsWith('/checkout') ? url : '/checkout');

const OAuthCallback = (props: OAuthCallbackProps) => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const env = queryParams.get('env');
    const state = queryParams.get('state');
    const error = queryParams.get('error');
    const error_description = queryParams.get('error_description');

    const [ready, setReady] = useState(false);

    useEffect(() => {
        sessionStorage.setItem('com.unboundid.kmxpos.env', env);
        handleCallback();
    }, []);

    const updateErrorPresentation = (presentation?: OAPErrorPresentation) => {
        console.log('[OAuthCallback] Sending error presentation change...');
        props.onErrorChange(presentation);
        window.location.replace('/checkout');
    };

    // TODO 2B: Handle routing as appropriate in processOAPError -- dependent on alert/modal system
    // implementation.
    const processOAPError = (error: OAPError) => {
        console.warn(`[OAuthCallback] ${JSON.stringify(error)}`);
        /*
         * Possible error codes here from OAP:
         * error.code - error.status - more info
         * missing_required_claim - 400 - required claim from userdata endpoint missing for user (msg in `description`)
         * ping_error (error = "invalid_grant"; "error_description": "Authorization code is invalid or expired.) - 400 - in the event the code was already used or is no longer valid
         * unauthorized_account_type - 401 - user didn't use numbered account (msg in `description`)
         * NULL - 403 Site Disabled - function is not running
         * error.status - 500 - OAP ran into an error processing
         *
         * OAP raised errors return:
         *  error_message: dict[code: str, description: str], status_code: Any | None = None)
         * unexpected 500s return:
         *  error.status [int for code]; error.statusText [str]
         */

        const error500 = process500Error(error);
        if (error500) {
            updateErrorPresentation(error500);
        }

        // If not a 500, check if we have something back from OAP. Process accordingly. For now,
        // only display the error; routing will be done in the future once we have an error screen
        // for UX.
        const error400 = process400Error(error);
        if (error400) {
            updateErrorPresentation(error400);
        }
    };

    const clearTempStorage = () => {
        var tempEnv = sessionStorage.getItem('com.unboundid.kmxpos.env');
        sessionStorage.clear();
        if (tempEnv) {
            sessionStorage.setItem('com.unboundid.kmxpos.env', tempEnv);
        }
        window.location.replace('/checkout');
    };

    const process500Error = (error: OAPError): OAPErrorPresentation | undefined => {
        const presentedError: OAPErrorPresentation = {
            title: 'Connection Issue',
            message: '',
            additionalDetails: synthesizeErrorDetails(error),
            requestId: error.request_id,
            statusNumber: `${getStatusCode(error)}`,
            tone: 'critical',
            action: clearTempStorage,
            actionLabel: 'Return to Login',
        };

        // If we get a 500, throw that back without looking further for anything OAP-direct.
        // Check if error.status is a string or int. String normally; num only if 500 unexpected response.
        if (error.status === 500) {
            console.warn(`[OAuthCallback] 500 response from OAuthProxy. ${error.status}: ${error.error_description}`);
            presentedError.message =
                'We ran into an issue while logging you in, and it looks like something went wrong on our end.';
            return presentedError;
        } else if (typeof error.status === 'number' && error.status_code === 500) {
            // This is an unexpected scenario
            console.warn(
                `[OAuthCallback] 500 response from OAuthProxy. ${error.status}: ${error.message} (${error.statusText})`
            );
            presentedError.message =
                'We ran into an issue while logging you in, and it looks like something went wrong on our end.';
            return presentedError;
        }

        return undefined;
    };

    const process400Error = (error: OAPError): OAPErrorPresentation | undefined => {
        if (error.status_code.toString().substring(0, 1) !== '4') {
            // Not a 400 Error
            return undefined;
        }

        console.warn(
            `[OAuthCallback] 400 response from OAuthProxy. ${error.status}: ${error.message} (${error.status_code})`
        );

        const presentedError: OAPErrorPresentation = {
            title: 'Login Issue',
            message: '',
            additionalDetails: synthesizeErrorDetails(error),
            requestId: error.request_id,
            statusNumber: `${getStatusCode(error)}`,
            tone: 'caution',
            action: clearTempStorage,
            actionLabel: 'Return to Login',
        };

        switch (error.status) {
            case 'missing_required_claim':
                presentedError.message =
                    'We ran into an issue logging you in. You may not have permission to access this app. Consider contacting IAM Team to ensure your account is properly configured.';

                // TODO: User should contact IAM team to ensure their account's claims are setup correctly
                // presentedError.actionLabel = "Contact IAM Team";
                // presentedError.action = this.contactIAMTeam;
                break;
            case 'ping_error': // includes invalid_grant; see OAP/auth_code:59
                // User Action: None
                if (error.code == 'invalid_grant') {
                    presentedError.title = 'Login Expired';
                    presentedError.message = "Your login has expired and you'll need to login again to continue.";
                    presentedError.tone = 'info';
                } else {
                    presentedError.message =
                        'We ran into an issue logging you in. This app may not be available right now. Try again later.';
                }
                break;
            case 'unauthorized_account_type':
                // User Action: Ensure login is with correct and valid account type
                presentedError.message =
                    "We ran into an issue logging you in. Check that your login is correct and that you're using a valid account type.";
                break;
            default:
                console.warn('[OAuthCallback] 400 default catch-all for OAuthCallback triggered.');
                presentedError.message = "We ran into an issue logging you in. Unfortunately, that's all we know.";
                break;
        }

        return presentedError;
    };

    /** Process error objects passed in as URL query items.
     * @returns A boolean indicating if execution should continue after evaluating.
     */
    const processURLQueryItemError = (error: string | undefined, errorDetail: string | undefined): boolean => {
        const presentedError: OAPErrorPresentation = {
            title: 'Login Issue',
            message: '',
            tone: 'info',
            action: clearTempStorage,
            actionLabel: 'Return to Login',
        };

        if (error) {
            presentedError.message = error;
            updateErrorPresentation(presentedError);
            return false;
        }

        if (errorDetail) {
            presentedError.message = errorDetail;
            updateErrorPresentation(presentedError);
            return false;
        }

        return true;
    };

    const handleAuthCode = (code: string) => {
        // Debug only!! Remove when finished
        // var env = sessionStorage.getItem('env');
        // console.log('Handling auth code', code);
        // console.log('Handling env', env)
        var url = new URL(OAUTH_PROXY.authCodeUri);
        var params = { code: code, app: appIdentifierOAP() };
        Object.keys(params).forEach(key => url.searchParams.append(key, (params as any)[key]));

        const handleTokenResponse = async () => {
            const res = await fetch(url, { method: 'POST' }).then(response => response.json());
            console.trace(`[OAuthCallback] Got a response from tokenResponse: ${res}`);
            if (res.status_code === 200) {
                console.log('[OAuthCallback] Good response, handling claims...');
                const claims = handleTokenResponseData(res);
                console.log(claims);
                setReady(!!claims);
            } else {
                console.warn('[OAuthCallback] Bad token response, processing error...');
                processOAPError(res);
            }
        };

        handleTokenResponse();
    };

    const handleCallback = () => {
        console.trace('[OAuthCallback] handleCallback fired');

        if (code && env) {
            handleAuthCode(code);
        } else {
            const canContinue = processURLQueryItemError(error, error_description);
            if (canContinue) {
                // If we make it this far, then this must be a logout response.
                setReady(true);
            }
        }
    };

    if (ready) {
        window.location.replace(
            state ? validateRedirectUrl(decodeURIComponent(DOMPurify.sanitize(state))) : '/checkout'
        );
    } else {
        return (
            <FlexRow className="sso-loader pt-5 justify-content-center">
                <hzn-loading size="medium" />
            </FlexRow>
        );
    }
};

export default OAuthCallback;
