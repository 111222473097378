import { useEventListener } from '../../hooks/useEventListener';
import { CustomElement } from '@horizon/common/types';
import { HznSelect, HznSelectHTML, HznSelectInstance } from '@horizon/select';
import React, { useRef } from 'react';

interface SelectProps {
    label: string;
    className?: string;
    onSelectionChanged: (value: string) => void;
}

const Select = ({
    label,
    className,
    onSelectionChanged,
    children,
    value,
    ...attrs
}: React.PropsWithChildren<SelectProps> & CustomElement<HznSelectInstance, HznSelectHTML>) => {
    const ref = useRef<HznSelect>();
    //@ts-ignore 'clear' is a synthetic event emitted by hzn-select
    useEventListener(ref, 'clear', target => onSelectionChanged(undefined));
    useEventListener(ref, 'input', target => onSelectionChanged(target.value));
    useEventListener(ref, 'change', target => onSelectionChanged(target.value));
    
    return (
        <hzn-select
            ref={ref}
            {...attrs}
            value={value ?? ''}
            class={className}
            name={label}
            aria-label={label}
            display-name={label}
        >
            {label}
            {children}
        </hzn-select>
    );
};

export default Select;
