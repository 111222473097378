import React, { useEffect } from 'react';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { useHardwareConnectionStatusQuery } from '../../api/CardFlowApi';
import { CardReaderConnectionStatus } from '../../api/electron/CardFlowApi';
import { selectCardReaderStatus, setCardReaderStatus } from '@src/store/HardwareSlice';

import '@horizon/icons/individual/hzn-warning';

export const CardReaderBadge = () => {
    const dispatch = useAppDispatch();
    const cardReaderStatus = useAppSelector(selectCardReaderStatus);

    const cardReader = useHardwareConnectionStatusQuery('', { pollingInterval: 1000 });

    useEffect(() => {
        const ready = cardReader.isError ? false : cardReader.isSuccess ? cardReader.data === CardReaderConnectionStatus.Connected : undefined;
        dispatch(setCardReaderStatus({ ready }));
    }, [cardReader]);

    if (cardReaderStatus.ready) {
        return (
            <hzn-badge label="Card reader" tone="positive" intense title="The card reader is ready for payment processing."/>
        );
    } else if (cardReaderStatus.ready === false) {
        return (
            <hzn-badge label="Card reader not responding" tone="caution" intense title="Debit cards cannot be processed at this time.">
                <hzn-icon-warning slot="leading-icon" />
            </hzn-badge>
        );
    } else {
        return (
            <hzn-badge label="Card reader" title="Waiting for card reader status information..." />
        );
    } 
};
