import classNames from 'classnames';
import React from 'react';
import './StepTitleHeader.scss';
import { FlexRow } from '@src/components/Flex';

export type WorkflowStepStatus = 'InProgress' | 'PartiallyComplete' | 'Completed';

export interface StepTitleHeaderProps {
    title: string;
    status: WorkflowStepStatus;
    loading?: boolean;
}

// "dumb" component that displays a title header on the left and a status icon on the right
// can be used for both Payment and Order Tender screens
export const StepTitleHeader = (props: StepTitleHeaderProps) => {
    return (
        <FlexRow className="stepTitleHeader">
            <div>
                <hzn-heading as="h2" size="small">
                    {props.title}
                </hzn-heading>
            </div>
            {props.loading && <hzn-loading />}
            <div className={classNames('status ms-auto', props.status)}></div>
        </FlexRow>
    );
};
