import React from 'react';
import { useEventListener } from '../../hooks/useEventListener';
import { HznBool } from '../../utils/Horizon';
import { HznButton } from '@horizon/button';

interface HorizonButtonProps extends Partial<Omit<HznButton, 'children'>> {
    className?: string;
    onClick: () => void;
}

export const HorizonButton = ({
    className,
    disabled,
    loading,
    children,
    onClick,
    ...props
}: React.PropsWithChildren<HorizonButtonProps>) => {
    const ref = React.useRef<HznButton | undefined>(undefined);

    useEventListener(ref, 'click', onClick);

    return (
        // @ts-ignore
        <hzn-button ref={ref} class={className} loading={HznBool(loading)} disabled={HznBool(disabled)} {...props}>
            {children}
        </hzn-button>
    );
};
