import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkflowProgression, WorkflowProgressionStep } from '@src/api/CheckoutApi';
import { Step, StepDescriptor } from '@src/components/Stepper/Stepper';
import { RootState } from './store';

export interface CheckoutState {
    sessionId?: string;
    steps?: Step[];
    activeStep?: Step;
}

const initialState: CheckoutState = {
    sessionId: undefined,
    steps: undefined,
    activeStep: undefined,
};


const MapToStepperSteps = (steps: WorkflowProgressionStep[]): StepDescriptor[] => {
    return steps.map(s => ({
        name: s.stepName,
        active: true,
        disabled: s.status === 'SKIPPED',
        state: 'NOT_STARTED',
        link: '../' + s.stepName.replace('-', '').replace(' ', '').toLowerCase(),
        remoteUrl: s.remoteUrl,
    }));
};

export const CheckoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setSteps: (state, action: PayloadAction<WorkflowProgression>) => {
            state.steps = MapToStepperSteps(action.payload.steps);
            state.sessionId = action.payload.contractSessionId;
        },
        setActiveStep: (state, action: PayloadAction<Step>) => {
            state.activeStep = action.payload;
        },
        reset: (state, action: Action) => initialState,
    },
});

export const { setSteps, setActiveStep, reset } = CheckoutSlice.actions;

export const selectSteps = (state: RootState) => state.checkout.steps;
export const selectActiveStep = (state: RootState) => state.checkout.activeStep;

export default CheckoutSlice.reducer;
