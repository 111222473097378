import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { CheckoutApi } from '../api/CheckoutApi';
import { CashDrawerApi } from '../api/CashDrawerApi';
import { CardFlowApi } from '../api/CardFlowApi';

import toastReducer from '../components/toast/ToastSlice';
import basketSearchReducer, { BasketSearchQuery } from '../pages/BasketSearch/BasketSearchSlice';
import authReducer from './AuthSlice';
import hardwareReducer from './HardwareSlice';
import paymentReducer from './PaymentSlice';
import checkoutReducer from './CheckoutSlice';
import { SystemInteractionApi } from '../api/SystemInteractionApi';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';


export const store = configureStore({
    reducer: {
        toast: toastReducer,
        payment: paymentReducer,
        basketSearch: persistReducer<BasketSearchQuery>({ key: 'basketSearch', storage }, basketSearchReducer),
        auth: authReducer,
        hardware: hardwareReducer,
        checkout: checkoutReducer,
        // Add the generated reducer as a specific top-level slice
        [CheckoutApi.reducerPath]: CheckoutApi.reducer,
        [CashDrawerApi.reducerPath]: CashDrawerApi.reducer,
        [CardFlowApi.reducerPath]: CardFlowApi.reducer,
        [SystemInteractionApi.reducerPath]: SystemInteractionApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            CheckoutApi.middleware,
            CashDrawerApi.middleware,
            CardFlowApi.middleware,
            SystemInteractionApi.middleware,
        ]),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
