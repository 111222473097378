import React from 'react';
import { AsCurrency } from '../utils/Formatting';
import { CardRow } from './Card';
import { OrderTenderSummaryDto } from '@src/api/CheckoutApi';

export interface SummaryTotalProps {
    summary: OrderTenderSummaryDto;
}

export const SummaryTotal = ({ summary }: SummaryTotalProps) => (
    <>
        <CardRow
            className="ps-3 pe-3"
            label={`Total Due to ${summary.equityTotal >= 0 ? 'Customer' : 'CarMax'}`}
            value={AsCurrency(Math.abs(summary.equityTotal))}
        />
        <CardRow className="ps-3 pe-3" label="Processed Payments" value={AsCurrency(summary.processedPaymentsTotal)} />
        <CardRow
            className="ps-3 pe-3"
            label={
                <hzn-text weight="bold" tone="info" size="large">
                    {`Balance Due to ${summary.equityTotal >= 0 ? 'Customer' : 'CarMax'}`}
                </hzn-text>
            }
            value={
                <hzn-text weight="bold" tone="info" size="large">
                    {AsCurrency(Math.abs(summary.equityTotal) - summary.processedPaymentsTotal)}
                </hzn-text>
            }
        />
    </>
);
