import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { HorizonButton } from './horizon/HorizonButton';

/**
 * Properties for a Generic Modal component.
 */
export interface GenericModalProps {
    title: string;
    message: string;
    alertHeading?: string;
    alertMessage?: string;
    footerText?: string;
    tone: "critical" | "info" | "caution";
    showCancel: boolean;
    cancel?: () => void;
    actionButtonTitle?: string;
    action?: (() => void);
    showModal: boolean;
}

/**
 * A generic modal with title, message, and buttons to retry / cancel.
 */
 export const GenericModal = (props: GenericModalProps) => {
     return (
        <Modal show={props.showModal} centered aria-label={props.title}>
            <Modal.Header className="horizon-modal">
                <hzn-text size="large" tone={props.tone}>
                    {props.title}
                </hzn-text>
            </Modal.Header>
            <Modal.Body className="horizon-modal">
                <div className="d-flex flex-column gap-4 p-2">
                    <hzn-text size="base" tone="body">
                        {props.message}
                    </hzn-text>
                    <hzn-stack space="medium">
                        {(props.alertMessage || props.alertHeading) && (
                            <hzn-alert
                                show={true}
                                role="status"
                                aria-label={props.alertMessage}
                                tone={props.tone}
                                heading={props.alertHeading}
                                sub-heading={props.alertMessage}
                            />
                        )}
                        {props.footerText &&
                            <hzn-text size="small" tone="subdued">
                                {props.footerText}
                            </hzn-text>
                        }
                    </hzn-stack>
                </div>
            </Modal.Body>
            <Modal.Footer className="horizon-modal">
                {props.actionButtonTitle && props.action && (
                    <HorizonButton
                        compact
                        variant="cta"
                        className="w-100"
                        onClick={props.action}
                    >
                        {props.actionButtonTitle}
                    </HorizonButton>
                )}
                {props.showCancel && <HorizonButton
                    compact
                    variant="secondary"
                    className="w-100"
                    onClick={props.cancel}
                >
                    Cancel
                </HorizonButton>}
            </Modal.Footer>
        </Modal>
    );
}
