import '@horizon/icons/individual/hzn-plus';
import { Card, CardRow } from '@src/components/Card';
import React, { useState } from 'react';
import { BasketDto } from '@src/api/CheckoutApi';
import { getTitleHolderName, getTotalEquity, getVehicleName } from '@src/utils/BasketFns';
import { AsCurrency } from '@src/utils/Formatting';
import { FlexColumn } from '@src/components/Flex';

export interface PaymentProps {
    basket: BasketDto;
}

export const PositiveEquity = ({ basket }: PaymentProps) => {
    const [verhicleName] = useState(getVehicleName(basket));
    const [equityAmount] = useState(AsCurrency(getTotalEquity(basket)));
    const [ownerNames] = useState(getTitleHolderName(basket));

    return (
        <hzn-stack space="medium" class="pt-3">
            <hzn-heading as="h3" size="xsmall" class="ps-3">
                Review Equity Bank Draft
            </hzn-heading>
            <ol>
                <li>Review the bank draft preview before continuing to the next step.</li>
                <li>The full amount of positive equity will be issued as a bank draft for an Appraisal-purchase.</li>
                <li>The bank draft will print after the cart is fully tendered.</li>
                <li>The bank draft will be issued to the owner(s) listed on the Title of the Appraisal-purchase vehicle.</li>
                <li>Partner with a Manager on Duty if an exception is needed on a bank draft.</li>
            </ol>
            <div className="ps-3 w-100">
                <Card title="Bank Draft Preview" titleHeader="h3" titleSize="xsmall">
                    <FlexColumn className="gap-3">
                        <CardRow
                            label={`Available equity from ${verhicleName}`}
                            value={<hzn-text weight="bold">{equityAmount}</hzn-text>}
                        />
                        <hzn-divider />
                        <CardRow
                            label={`Amount to be issued as bank draft`}
                            value={<hzn-text weight="bold">{equityAmount}</hzn-text>}
                        />
                        <hzn-divider />
                        <CardRow label="Bank draft to be issued to" value={ownerNames} />
                    </FlexColumn>
                </Card>
            </div>
        </hzn-stack>
    );
};
