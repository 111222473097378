import React, { useRef } from 'react';
import { useEventListener } from '../../hooks/useEventListener';
import { HznText } from '@horizon/text';
import './HorizonTextNav.scss'

interface HorizonTextNavProps {
    onClick: () => void;
    /**
     * Sets the font size of text within element
     */
    size?: 'small' | 'base' | 'large';
}

export const HorizonTextNav = ({ onClick, size = 'base', children }: React.PropsWithChildren<HorizonTextNavProps>) => {
    const ref = useRef<HznText>();

    useEventListener(ref, 'click', onClick);

    return (
        <hzn-text ref={ref} size={size} tone="interactive" class="interactive-text-action">
            {children}
        </hzn-text>
    );
};
