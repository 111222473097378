import { CurrencyInput } from '@src/components/horizon/CurrencyInput';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import './PaymentAddCard.scss';
import React, { useMemo, useRef, useState } from 'react';
import Select from '@src/components/horizon/Select';
import '@horizon/icons/individual/hzn-trash';
import '@horizon/icon-button/hzn-icon-button';
import {
    PaymentDto,
    UpdatePaymentRequest,
    useAddBasketPaymentMutation,
    useUpdatePaymentMutation,
} from '@src/api/CheckoutApi';
import { FlexRow } from '@src/components/Flex';
import { InputErrorMessages, InputHandle } from '@src/components/horizon/Input';
import { AsCurrency } from '@src/utils/Formatting';

const MaxCheckAmount = 250;
const MinAmount = 0.01;
const ValidDollarAmtRequiredMessage = 'A valid dollar amount is required.';
const MaxCheckAmountMessage = 'A maximum amount of $250 will be accepted.';
const MaxNonCheckAmountMessage = 'Amount cannot be greater than outstanding balance.';

export type PaymentTypes = 'CASH' | 'CHECK' | 'DEBIT' | 'CERTIFIED_FUNDS';
export type AddPaymentAllowedTypes = 'NONE' | PaymentTypes;

export interface PaymentAddCardProps {
    basketId: string;
    partyId: string;
    payment?: PaymentDto;
    balance: number;
    onClose: () => void;
}

interface Validations {
    min: number;
    max: number;
    errorMessages: InputErrorMessages;
}

const StandardErrorMessages: InputErrorMessages = {
    valueMissing: ValidDollarAmtRequiredMessage,
    valid: ValidDollarAmtRequiredMessage,
    rangeUnderflow: ValidDollarAmtRequiredMessage,
};

const resolvePaymentValidations = (payment: PaymentDto, paymentType: string, balance: number): Validations => {
    // the max allowed amount for this payment. When editing a payment the max allowed should include the amount of the current payment.
    const maxAllowedAmount = balance + (payment?.amount ?? 0);

    switch (paymentType) {
        case 'CHECK': {
            return {
                min: MinAmount,
                // Cap personal checks at $250
                max: Math.min(MaxCheckAmount, maxAllowedAmount),
                errorMessages: { ...StandardErrorMessages, rangeOverflow: MaxCheckAmountMessage },
            };
        }
        default: {
            return {
                min: MinAmount,
                max: maxAllowedAmount,
                errorMessages: { ...StandardErrorMessages, rangeOverflow: MaxNonCheckAmountMessage },
            };
        }
    }
};

export const PaymentAddCard = ({ basketId, partyId, payment, balance, onClose }: PaymentAddCardProps) => {
    const [addPayment, addPaymentState] = useAddBasketPaymentMutation();
    const [updatePayment, updatePaymentState] = useUpdatePaymentMutation();

    const currencyRef = useRef<InputHandle>();
    const [paymentType, setPaymentType] = useState<PaymentTypes | undefined>(
        (payment?.paymentType as PaymentTypes) ?? 'CASH'
    );
    const [paymentAmount, setPaymentAmount] = useState<number | undefined>(payment?.amount);
    const paymentValidations = useMemo(
        () => resolvePaymentValidations(payment, paymentType, balance),
        [payment, paymentType, balance]
    );

    const onSavePayment = () => {
        if (!currencyRef.current.validate()) return;
        if (!payment) {
            addPayment({
                basketId,
                body: {
                    paymentType,
                    amount: paymentAmount,
                    lineOfBusinessProvided: false,
                    direction: 'INBOUND',
                    partyRole: {
                        partyId,
                        relationship: 'SELLER',
                    },
                },
            })
                .unwrap()
                .then(() => onClose())
                .catch(err => console.log('Save payment error', err));
        } else {
            updatePayment({
                basketId,
                paymentId: payment.id,
                body: { paymentType, amount: paymentAmount } as UpdatePaymentRequest,
            })
                .unwrap()
                .then(onClose)
                .catch(err => console.log('Update payment error', err));
        }
    };

    return (
        <>
            <FlexRow className={'align-items-top flex-fill'}>
                <Select
                    className={'paymentInput'}
                    value={paymentType}
                    onSelectionChanged={value => setPaymentType(value as PaymentTypes)}
                    label="Select a payment method"
                >
                    <option value="CASH">Cash</option>
                    <option value="CERTIFIED_FUNDS">Certified Funds</option>
                    <option value="CHECK">Check (max $250)</option>
                    <option value="DEBIT">Debit Card</option>
                </Select>
                <CurrencyInput
                    ref={currencyRef}
                    value={paymentAmount}
                    required
                    min={paymentValidations.min}
                    max={paymentValidations.max}
                    errorMessages={paymentValidations.errorMessages}
                    onValueChanged={setPaymentAmount}
                    className="w-50"
                >
                    Amount
                </CurrencyInput>
            </FlexRow>
            <FlexRow>
                <HorizonButton
                    compact
                    variant="primary"
                    onClick={() => onSavePayment()}
                    loading={addPaymentState.isLoading || updatePaymentState.isLoading}
                >
                    Save Payment
                </HorizonButton>
                <HorizonButton
                    compact
                    variant="secondary"
                    onClick={() => onClose()}
                    disabled={addPaymentState.isLoading || updatePaymentState.isLoading}
                >
                    Cancel
                </HorizonButton>
            </FlexRow>
        </>
    );
};
