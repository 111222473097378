import React, { useCallback, useRef } from 'react';
import { FlexColumn } from '../Flex';
import { HznPagination } from '@horizon/pagination';
import { useEventListener } from '../../hooks/useEventListener';

interface PagerProps {
    /** Zero-based index of the current page. Optional. */
    pageNumber?: number;
    /** The total number of pages. */
    totalCount: number;
    /** An event fired with the one-based index of the new page. */
    onPageChange: (page: number) => void;
    /** An event fired with the new number of items per page. */
    onItemsPerPageChange: (itemsPerPage: number) => void;
    /** The Horizon Pagination component accepts an alpha-numeric item-name attribute which allows you to provide a label for the paginated content other than the default values “item” and “items“.
        The value provided to item-name can be a space-separated value containing both the singular form and plural form (e.g. 'goose geese'). Alternatively, a single word may be passed—the component will create the plural form by appending the letter “s” to the end. */
    itemName?: string;
}

interface PaginationDetails {
    newItemsPerPage: number;
    newTotalPages: number;
    newCurrentPage: number;
}
interface PaginationEvent extends Event {
    detail: PaginationDetails;
}
export const Pager = ({ pageNumber, totalCount, onPageChange, onItemsPerPageChange, itemName }: PagerProps) => {
    const ref = useRef<HznPagination>();

    const pageChangeListener = useCallback(
        (_: HznPagination, ev: PaginationEvent): void => {
            onPageChange(ev.detail.newCurrentPage);
        },
        [onPageChange]
    );

    const itemsPerPageChangeListener = useCallback(
        (_: HznPagination, ev: PaginationEvent): void => {
            onItemsPerPageChange(ev.detail.newItemsPerPage);
        },
        [onItemsPerPageChange]
    );

    // hzn-pagination events: https://horizon.carmax.com/build/components/pagination/code/#events-and-payloads
    //@ts-ignore hzn-pagination has synthtetic 'page-change' event
    useEventListener(ref, 'page-change', pageChangeListener);
    //@ts-ignore hzn-pagination has synthtetic 'next' event
    useEventListener(ref, 'next', pageChangeListener);
    //@ts-ignore hzn-pagination has synthtetic 'previous' event
    useEventListener(ref, 'previous', pageChangeListener);
    //@ts-ignore hzn-pagination has synthtetic 'items-per-page-change' event
    useEventListener(ref, 'items-per-page-change', itemsPerPageChangeListener);

    return (
        <FlexColumn className="align-items-center">
            <hzn-pagination
                class="pager"
                ref={ref}
                item-name={itemName}
                total-items={totalCount}
                current-page={pageNumber}
                items-per-page-options="[25]"
            />
        </FlexColumn>
    );
};
