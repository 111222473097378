import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Claims } from '../models/Claims';
import { RootState } from './store';

export interface AuthState {
    accessToken: string | undefined;
    claims: Claims | undefined;
}

const initialState: AuthState = {
    accessToken: undefined,
    claims: undefined,
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthState: (state, action: PayloadAction<AuthState>) => {
            state.claims = action.payload.claims;
            state.accessToken = action.payload.accessToken;
        },
    },
});

export const { setAuthState } = AuthSlice.actions;

export const selectClaims = (state: RootState) => state.auth.claims;
export const selectIsLoggedIn = (state: RootState) => state.auth.claims !== undefined;

export default AuthSlice.reducer;


