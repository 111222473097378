import React, { useEffect } from 'react';

import '@horizon/icons/individual/hzn-warning';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { DrawerStatus, useHardwareCashDrawerGetStatusQuery } from '@src/api/CashDrawerApi';
import { useGetWorkstationByNameAndLocationQuery } from '@src/api/CheckoutApi';
import { useSystemInteractionGetComputerNameQuery } from '@src/api/SystemInteractionApi';
import { selectCashDrawerStatus, setCashDrawerStatus } from '@src/store/HardwareSlice';

import '@horizon/icons/individual/hzn-danger';
import '@horizon/icons/individual/hzn-warning';

const readyStatuses = [DrawerStatus.Open, DrawerStatus.Closed];

export const CashDrawerBadge = () => {
    const dispatch = useAppDispatch();
    const cashDrawerStatus = useAppSelector(selectCashDrawerStatus);

    const { storeLocationId } = useParams();
    const cashDrawer = useHardwareCashDrawerGetStatusQuery('', { pollingInterval: 1000 });
    const workstationName = useSystemInteractionGetComputerNameQuery({});
    // Fetch workstation assignment details every 60 seconds. 
    // This keeps the badge current and by side effect keeps the access tokens fresh. 
    const workstation = useGetWorkstationByNameAndLocationQuery(
        { name: workstationName.data, storeLocationId },
        { refetchOnMountOrArgChange: true, skip: !storeLocationId || !workstationName.isSuccess, pollingInterval: 60000 }
    );

    useEffect(() => {
        const assigned = workstationName.isError || workstation.isError ? false : workstation.isSuccess ? workstation.data.isDrawerAssigned : undefined;
        const ready = cashDrawer.isError ? false : cashDrawer.isSuccess ? readyStatuses.includes(cashDrawer.data) : undefined;
        dispatch(setCashDrawerStatus({ assigned, ready }));
    }, [workstationName, workstation, cashDrawer]);

    if (cashDrawerStatus.assigned && cashDrawerStatus.ready) {
        return (
            <hzn-badge label="Cash drawer" tone="positive" intense title="The cash drawer is ready for payment processing."/>
        );
    } else if (cashDrawerStatus.assigned === false) {
        return (
            <hzn-badge label="Cash drawer not assigned" tone="critical" intense title="Please assign a cash drawer to this workstation in eCMS.">
                <hzn-icon-danger slot="leading-icon" />
            </hzn-badge>
        );
    } else if (cashDrawerStatus.ready === false) {
        return (
            <hzn-badge label="Cash drawer not responding" tone="caution" intense title="You will need to open the cash drawer manually using a key.">
                <hzn-icon-warning slot="leading-icon" />
            </hzn-badge>
        );
    } else {
        return (
            <hzn-badge label="Cash drawer" intense title="Waiting for cash drawer status information..." />
        );
    }
};
