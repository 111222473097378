import React, { useMemo } from 'react';
import {
    ColumnDef,
    Header,
    OnChangeFn,
    SortingState,
    Updater,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { Placeholder, Table } from 'react-bootstrap';
import classNames from 'classnames';
import './DataTable.scss';
import '@horizon/icons/individual/hzn-arrow-up';
import '@horizon/icons/individual/hzn-arrow-down';

interface DataTableProps<T> {
    label: string;
    data: T[];
    loading: boolean;
    columns: ColumnDef<T, any>[];
    onRowClick?: (row: T) => void;
    sort?: SortingState;
    onSortChange?: (update: SortingState) => void;
}

const headerSort = <T,>(header: Header<T, unknown>) => (
    <hzn-text>
        {{
            asc: <hzn-icon-arrow-up size='small' />,
            desc: <hzn-icon-arrow-down size='small'/>,
        }[header.column.getIsSorted() as string] ?? null}
    </hzn-text>
);

export const DataTable = <T,>(props: DataTableProps<T>) => {
    const dataMemo = React.useMemo(
        () => (props.loading && props.data.length == 0 ? Array(7).fill({}) : props.data),
        [props.loading, props.data]
    );

    const columnsMemo = useMemo(
        () =>
            props.loading
                ? props.columns.map(column => ({
                      ...column,
                      cell: () => (
                          <Placeholder animation="wave" >
                              <Placeholder className="d-block" bg='secondary' />
                          </Placeholder>
                      ),
                  }))
                : props.columns,
        [props.loading, props.columns]
    );

    const onSortingChange: OnChangeFn<SortingState> = (updater: Updater<SortingState>) => {
        const newSort: SortingState = updater instanceof Function ? updater(props.sort) : updater;
        // Column sort will cycle between asc -> desc -> "default" (empty). Short circuit this behavior to just toggle between asc/desc.
        const resolvedSort: SortingState = newSort.length == 0 ? [{ ...props.sort[0], desc: false }] : newSort;
        props.onSortChange(resolvedSort);
    };

    const table = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        getCoreRowModel: getCoreRowModel(),
        manualSorting: !!props.onSortChange ? true : undefined,
        state: props.sort ? { sorting: props.sort } : undefined,
        onSortingChange: props.onSortChange ? onSortingChange : undefined,
    });

    return (
        <hzn-card border-radius="medium" elevation="flat">
            <Table className="data-table" hover aria-label={props.label}>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr className="summary-header" key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th
                                    key={header.id}
                                    className="summary-header-cell"
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <div className={header.column.getCanSort() ? 'sortable d-flex gap-2 align-items-baseline' : ''}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {props.onSortChange && headerSort(header)}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr
                            className={classNames('summary-row', !!props.onRowClick ? 'has-click-action' : '')}
                            key={row.id}
                            onClick={() => !!props.onRowClick && props.onRowClick(row.original)}
                        >
                            {row.getVisibleCells().map(cell => (
                                <td className="summary-row-cell" key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </hzn-card>
    );
};
