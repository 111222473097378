import React from "react";
import { Card } from "./Card";

interface HeadedContainerCardProps {
    title: string;
}
export const HeadedContainerCard = ({ title, children }: React.PropsWithChildren<HeadedContainerCardProps>) => (
    <Card title={title} titleHeader="h3" titleSize="xsmall">
        {children}
    </Card>
);