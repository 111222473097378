import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortingState } from '@tanstack/table-core';
import type { RootState } from '../../store/store';
import { BasketPaymentState, BasketStatus, GetBasketSummariesApiArg } from '@src/api/CheckoutApi';
import { PaymentFilterNames } from '@src/utils/Formatting';

interface BasketSearchSort {
    column: string;
    direction: 'asc' | 'desc';
}

interface BasketSearchPage {
    pageNumber: number;
    pageSize: number;
}

export interface BasketSearchQuery {
    sellingLocationId: string | undefined;
    term: string | undefined;
    cartType: string | undefined;
    paymentState: string | undefined;
    page: BasketSearchPage;
    sort: SortingState;
}

const initialState: BasketSearchQuery = {
    sellingLocationId: undefined,
    term: undefined,
    cartType: undefined,
    paymentState: undefined,
    page: { pageNumber: 1, pageSize: 25 },
    sort: [{ id: 'createdOnUtc', desc: true }],
};

export const BasketSearchSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        updateSellingLocation: (state, action: PayloadAction<string | undefined>) => {
            state.page.pageNumber = 1;
            state.sellingLocationId = action.payload;
        },
        updateTerm: (state, action: PayloadAction<string | undefined>) => {
            state.page.pageNumber = 1;
            state.term = action.payload;
        },
        updateCartType: (state, action: PayloadAction<string | undefined>) => {
            state.page.pageNumber = 1;
            state.cartType = action.payload;
        },
        updatePaymentState: (state, action: PayloadAction<string | undefined>) => {
            state.page.pageNumber = 1;
            state.paymentState = action.payload;
        },
        updatePageNumber: (state, action: PayloadAction<number>) => {
            state.page.pageNumber = action.payload;
        },
        updatePageSize: (state, action: PayloadAction<number>) => {
            state.page.pageSize = action.payload;
        },
        updateSorting: (state, action: PayloadAction<SortingState>) => {
            state.sort = action.payload;
        },
        reset: state => {
            state.term = initialState.term;
            state.cartType = initialState.cartType;
            state.paymentState = initialState.paymentState;
            state.page = initialState.page;
            state.sort = initialState.sort;
        },
    },
});

export const { updateSellingLocation, updateTerm, updateCartType, updatePaymentState, updatePageNumber, updatePageSize, updateSorting, reset } =
    BasketSearchSlice.actions;

export const selectBasketSearchQuery = (state: RootState) => state.basketSearch;

const resolveStatusFilter = (paymentState: string) : BasketStatus[] => {
    switch (paymentState) {
        case PaymentFilterNames.Completed: return ["COMPLETED"];
        default: return ["ACTIVE"];
    }
}

const resolvePaymentStateFilter = (paymentState: string) : BasketPaymentState[] | undefined => {
    switch (paymentState) {
        case PaymentFilterNames.ReadyToCheckout: return ["NEW"];
        case PaymentFilterNames.InProgress: return ["PARTIALLY_PAID", "PAID"];
        case PaymentFilterNames.Error: return ["ERROR"];
        default: return undefined;
    }
}

export const selectBasketSearchRequest = ({ basketSearch }: RootState): GetBasketSummariesApiArg => {
    const sellingLocationId = basketSearch.sellingLocationId;
    const term = basketSearch.term;
    const statuses = resolveStatusFilter(basketSearch.paymentState)
    const paymentStates = resolvePaymentStateFilter(basketSearch.paymentState);
    const pageNumber = basketSearch.page.pageNumber;
    const pageSize = basketSearch.page.pageSize;
    const sortField = basketSearch.sort.length === 0 ? 'createdOnUtc' : basketSearch.sort[0].id;
    const sortDirection = basketSearch.sort.length === 0 ? 'ASC' : basketSearch.sort[0].desc ? 'DESC' : 'ASC';
    return { sellingLocationId, term, statuses, paymentStates, pageNumber, pageSize, sortField, sortDirection };
};

export default BasketSearchSlice.reducer;
