import { FlexRow } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment, CertifiedFundsPaymentDetails } from '@src/store/PaymentSlice';
import { AsCurrency } from '@src/utils/Formatting';
import React from 'react';

export const CertifiedFundsPaymentDescription = () => {
    const { payment, paymentDetails } = useAppSelector(selectPayment);
    const certifiedFundsDetails = paymentDetails as CertifiedFundsPaymentDetails;
    const refundDue =
        certifiedFundsDetails?.checkAmount > payment.amount
            ? certifiedFundsDetails.checkAmount - payment.amount
            : undefined;

    return (
        <>
            <FlexRow className={'justify-content-between pt-3 ps-2 pe-2'}>
                <hzn-text tone="subdued">Payment method</hzn-text>
                <hzn-text>Certified Funds</hzn-text>
            </FlexRow>
            <hzn-divider />
            {!certifiedFundsDetails && (
                <FlexRow className={'justify-content-between ps-2 pe-2'}>
                    <hzn-text tone="subdued">Amount</hzn-text>
                    <hzn-text>{AsCurrency(payment.amount)}</hzn-text>
                </FlexRow>
            )}
            {certifiedFundsDetails && (
                <>
                    <FlexRow className={'justify-content-between ps-2 pe-2'}>
                        <hzn-text tone="subdued">Check Amount</hzn-text>
                        <hzn-text>{AsCurrency(certifiedFundsDetails.checkAmount)}</hzn-text>
                    </FlexRow>
                    <hzn-divider />
                    <FlexRow className={'justify-content-between ps-2 pe-2'}>
                        <hzn-text tone="subdued">Amount applied to balance</hzn-text>
                        <hzn-text>{AsCurrency(payment.amount)}</hzn-text>
                    </FlexRow>
                    <hzn-divider />
                    {refundDue && (
                        <>
                            <FlexRow className={'justify-content-between ps-2 pe-2'}>
                                <hzn-text tone="subdued">Mail refund due</hzn-text>
                                <span className="stress">{AsCurrency(refundDue)}</span>
                            </FlexRow>
                            <hzn-divider />
                        </>
                    )}
                    <FlexRow className={'justify-content-between ps-2 pe-2'}>
                        <hzn-text tone="subdued">Check Number</hzn-text>
                        <hzn-text>{certifiedFundsDetails.checkNumber}</hzn-text>
                    </FlexRow>
                    <HorizonAlert
                        show={!!refundDue}
                        heading="Mail refund will be issued automatically."
                        tone="info"
                        sub-heading="The refund due to customer will be mailed as a refund check, per CarMax policy. Customers should allow up to 14 business days to receive their refund."
                    ></HorizonAlert>
                </>
            )}
        </>
    );
};
