import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface CashDrawerStatus {
    assigned?: boolean;
    ready?: boolean;
}

export interface CardReaderStatus {
    ready?: boolean;
}

export interface HardwareState {
    cashDrawer: CashDrawerStatus;
    cardReader: CardReaderStatus;
}

const initialState: HardwareState = {
    cashDrawer: { assigned: undefined, ready: undefined },
    cardReader: { ready: undefined },
};

export const HardwareSlice = createSlice({
    name: 'hardware',
    initialState,
    reducers: {
        setCashDrawerStatus: (state, action: PayloadAction<CashDrawerStatus>) => {
            state.cashDrawer = action.payload;
        },
        setCardReaderStatus: (state, action: PayloadAction<CardReaderStatus>) => {
            state.cardReader = action.payload;
        },
    },
});

export const { setCashDrawerStatus, setCardReaderStatus  } = HardwareSlice.actions;

export const selectCashDrawerStatus = (state: RootState) => state.hardware.cashDrawer;
export const selectCardReaderStatus = (state: RootState) => state.hardware.cardReader;
export const selectCardReaderReady = (state: RootState) => state.hardware.cardReader.ready;

export default HardwareSlice.reducer;
