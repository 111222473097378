import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { CashDrawerApi, DrawerStatus, OpenDrawerStatus } from './electron/CashDrawerApi';

interface HardwareCashDrawerError {
    status: number;
    statusText: string;
    data: any;
}

const cashDrawer = (): CashDrawerApi => {
    if (!window.CashDrawer) throw 'Cash Drawer hardware interface not found.';
    return window.CashDrawer;
};

const cashDrawerTag = ['HardwareCashDrawer'];

const cashDrawerApi = createApi({
    reducerPath: 'cashDrawer',
    baseQuery: fakeBaseQuery<HardwareCashDrawerError>(),
    endpoints: () => ({}),
})
    .enhanceEndpoints({
        addTagTypes: cashDrawerTag,
    })
    .injectEndpoints({
        endpoints: builder => ({
            hardwareCashDrawerGetStatus: builder.query<DrawerStatus, any>({
                queryFn: async () => {                    
                    try {
                        const status = await cashDrawer().getCashDrawerStatusAsync();
                        return { data: status };
                    } catch (ex) {
                        return { error: { data: DrawerStatus.Error, status: 0, statusText: ex } };
                    }
                },
                providesTags: cashDrawerTag,
            }),
            hardwareCashDrawerOpen: builder.mutation<OpenDrawerStatus, any>({
                queryFn: async () => {
                    try {
                        const status = await cashDrawer().openCashDrawerAsync();
                        return { data: status };
                    } catch (ex) {
                        return { error: { data: OpenDrawerStatus.Error, status: 0, statusText: ex } };
                    }
                },
                invalidatesTags: cashDrawerTag,
            }),
        }),
    });

export { cashDrawerApi as CashDrawerApi };
export { DrawerStatus, OpenDrawerStatus }; // re-export common types. 
export const { useHardwareCashDrawerGetStatusQuery, useHardwareCashDrawerOpenMutation } = cashDrawerApi;
