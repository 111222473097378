// Note: The contents of this file must exactly match the cardFlowApi.ts file in
// the Electron app. We don't anticipate these APIs changing frequently, so we're
// opting with this approach rather than creating an NPM package that both the
// Desktop Electron app and this site references.

// Source:
// https://github.com/CarMax-Internal/commerce-point-of-sale-desktop/blob/main/src/CarMax.PointOfSale.DesktopApp/src/common/cardFlowApi.ts

export enum CardReaderConnectionStatus {
    Error = 'Error',
    Disconnected = 'Disconnected',
    Connected = 'Connected',
}

export enum CardReaderInputStatus {
    Cancelled = 'Cancelled',
    Error = 'Error',
    Manual = 'Manual',
    Retrying = 'Retrying',
    Success = 'Success',
}

export enum CardFlowEvent {
    TransactionRejected = 'transactionRejected',
    TransactionConfirmed = 'transactionConfirmed',
    CardEntryCancelled = 'cardEntryCancelled',
    CardEntryErrored = 'cardEntryErrored',
    CardEntryManual = 'cardEntryManual',
    CardEntryRetrying = 'cardEntryRetrying',
    CardEntryMaxRetry = 'cardEntryMaxRetry',
    CardEntrySuccess = 'cardEntrySuccess',
    PINEntryFailed = 'pinEntryFailed',
    PINEntrySuccess = 'pinEntrySuccess',
    SignaturePending = 'signaturePending',
}

export type CardReaderResult = {
    cardReaderStatus: CardReaderInputStatus;
    cardFlowEvent: CardFlowEvent;
    data: TransactionResult | undefined;
};

export type TransactionResult = {
    accountNumberSuffix: string;
    expirationDate: string;
    cardDetailsCollectionMethod: CardDetailsCollectionMethod;
    encryptedPin: string;
    bin: string;
    encryptedData: string;
    keyData: string;
    pinKey: string;
    terminalId: string;
};

export type CardDetailsCollectionMethod = 'SWIPED' | 'MANUALLY_KEYED';

export type CardFlowApi = {
    /** Gets the status of the card reader hardware connection.
     */
    getConnectionStatus: () => Promise<CardReaderConnectionStatus>;

    /** Initiates a card-swipe transaction on the card reader.
     *
     * This function will check the status of the card reader hardware, attempt to claim it,
     * and begin the card swipe process. Card data and associated status information are
     * emitted via the returned observable publisher.
     *
     * @param customer The customer's full name.
     * @param amount The total transaction amount in USD, with no more than 2 decimals.
     * @param requireSignature Set to `true` to require a signature from the customer.
     *
     * @returns A `CardReaderResult` promise that completes after the card input is successful,
     * or has been retried the given number of times.
     */
    requestCardSwipe: (customer: string, amount: number, requireSignature: boolean) => Promise<CardReaderResult>;
};
