import { createColumnHelper } from '@tanstack/react-table';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { AsCurrency, PaymentFilterNames } from '../../utils/Formatting';
import './BasketSearchResults.scss';
import { selectBasketSearchQuery, updateSorting } from './BasketSearchSlice';
import { DataTable } from '../../components/DataTable/DataTable';
import { BasketSummaryDto, BasketSummaryDtoPaginatedListDto } from '@src/api/CheckoutApi';
import { FlexRow } from '@src/components/Flex';
import '@horizon/icons/individual/hzn-external-link';

const columnHelper = createColumnHelper<BasketSummaryDto>();

export const paymentStateBadge = (summary: BasketSummaryDto) => {
    switch (summary.status) {
        case 'COMPLETED':
            return <hzn-badge label={PaymentFilterNames.Completed} tone="positive" />;
        case 'ABANDONED':
            return <hzn-badge label={PaymentFilterNames.Abandoned} tone="critical" />;
        case 'ACTIVE':
            switch (summary.paymentState) {
                case 'NEW':
                    return <hzn-badge label={PaymentFilterNames.ReadyToCheckout} tone="info" intense />;
                case 'PAID':
                case 'PARTIALLY_PAID':
                    return <hzn-badge label={PaymentFilterNames.InProgress} tone="info" />;
                case 'ERROR':
                    return <hzn-badge label={PaymentFilterNames.Error} tone="critical" intense />;
            }
    }
};

export const highlight = (str: string, term: string | undefined) => {
    if (!term) return str;
    const matchIndex = str.toLowerCase().indexOf(term.toLowerCase());
    if (matchIndex == -1) return str;

    return (
        <>
            {str.slice(0, matchIndex)}
            <span className="fw-bold highlight">{str.slice(matchIndex, matchIndex + term.length)}</span>
            {str.slice(matchIndex + term.length, str.length)}
        </>
    );
};

const columns = (query: string | undefined, launchPosClient: boolean) => [
    columnHelper.accessor('createdOnUtc', {
        header: () => (
            <hzn-text align="left" weight="bold" as="div">
                Cart created on
            </hzn-text>
        ),
        cell: info => (
            <hzn-text title={format(parseISO(info.getValue()), 'P p')}>
                {format(parseISO(info.getValue()), 'P')}
            </hzn-text>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor('customerName', {
        header: () => (
            <hzn-text align="left" weight="bold" as="div">
                Customer name
            </hzn-text>
        ),
        cell: info => (
            <FlexRow className="gap-1 align-items-baseline">
                <hzn-text>{highlight(info.getValue(), query)}</hzn-text>
                {launchPosClient && (
                    <div className="external-link-container" title="This cart will open in a new page.">
                        <hzn-icon-external-link size="small" class="external-link" />
                    </div>
                )}
            </FlexRow>
        ),
        enableSorting: true,
    }),
    columnHelper.display({
        id: 'cartType',
        header: () => (
            <hzn-text align="left" weight="bold" as="div">
                Items
            </hzn-text>
        ),
        cell: () => <hzn-text>Appraisal Purchase </hzn-text>,
        enableSorting: false,
    }),
    columnHelper.accessor('decoratorsSummary', {
        header: '',
        cell: info => {
            const decorator = info.getValue().filter(s => s.type === 'VIN');
            const vin = !decorator.length || !decorator[0].values?.length ? "" : decorator[0].values[0];
            return <div className="monospace small">{highlight(vin, query)}</div>;
        },
        enableSorting: false,
    }),
    columnHelper.accessor('equity',
        {
            id: 'Total Due',
            header: () => (
                <hzn-text align="right" weight="bold" as="div">
                    Total due
                </hzn-text>
            ),
            cell: info => (
                <hzn-text align="right" as="div">
                    {AsCurrency(-1 * info.getValue())}
                </hzn-text>
            ),
            enableSorting: false,
        }
    ),
    columnHelper.accessor(summary => summary, {
        id: 'Cart Status',
        header: () => (
            <hzn-text align="center" weight="bold" as="div">
                Cart status
            </hzn-text>
        ),
        cell: info => <div className="pill d-flex justify-content-center">{paymentStateBadge(info.getValue())}</div>,
        enableSorting: false,
    }),
];

// For POS Client launches (in BusOpsNow, etc) resolve a gateway URL to match the current environment.
// This URL will be opened in a new tab and will initiate the POS client launch.
const resolveGatewayUrl = (basket: BasketSummaryDto) => {
    var gatewayEnv = window.location.host.toLowerCase().includes('-qa') ? 'qa' : 'prod';
    return `https://commerce-checkout-web-${gatewayEnv}-east-site.azurewebsites.net/gateway/${basket.sellingLocationId}/${basket.id}`;
};

interface BasketSearchResultsProps {
    query: string | undefined;
    loading: boolean;
    results: BasketSummaryDtoPaginatedListDto | undefined;
    launchPosClient: boolean;
}

export const BasketSearchResults = ({ query, loading, results, launchPosClient }: BasketSearchResultsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { sort: sorting } = useAppSelector(selectBasketSearchQuery);

    const OnRowClick = (basket: BasketSummaryDto) => {
        // If a POS client launch is requested, navigate to the /gateway route to open this basket in the POS app.
        // Otherwise just navigate to the basket details page.
        if (launchPosClient) {
            const gatewayUrl = resolveGatewayUrl(basket);
            console.log('Point of Sale client launch requested', gatewayUrl);
            window.open(gatewayUrl, '_blank').focus();
        } else {
            navigate(basket.id);
        }
    };

    return (
        <div className="basket-search">
            <DataTable
                label="Cart search results"
                columns={columns(query, launchPosClient)}
                data={results?.results ?? []}
                loading={loading}
                onRowClick={OnRowClick}
                onSortChange={newSort => dispatch(updateSorting(newSort))}
                sort={sorting}
            />
        </div>
    );
};
