import { FlexRow } from '@src/components/Flex';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment, CheckPaymentDetails } from '@src/store/PaymentSlice';
import { AsCurrency } from '@src/utils/Formatting';
import React from 'react';

export const CheckPaymentDescription = () => {
    const { payment, paymentDetails } = useAppSelector(selectPayment);
    const checkDetails = paymentDetails as CheckPaymentDetails;

    return (
        <>
            <FlexRow className={'justify-content-between pt-3 ps-2 pe-2'}>
                <hzn-text tone="subdued">Payment method</hzn-text>
                <hzn-text>Check</hzn-text>
            </FlexRow>
            <hzn-divider />
            <FlexRow className={'justify-content-between ps-2 pe-2'}>
                <hzn-text tone="subdued">Amount</hzn-text>
                <hzn-text>{AsCurrency(payment.amount)}</hzn-text>
            </FlexRow>
            {checkDetails && (
                <>
                    <hzn-divider />
                    <FlexRow className={'justify-content-between ps-2 pe-2'}>
                        <hzn-text tone="subdued">Check Number</hzn-text>
                        <hzn-text>{checkDetails.checkNumber}</hzn-text>
                    </FlexRow>
                </>
            )}
        </>
    );
};
