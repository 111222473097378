import Dinero from 'dinero.js';
import { BasketPaymentState } from '../api/CheckoutApi';

export const AsCurrency = (value: number) => {
    const formatted = Dinero({ amount: Math.round(value * 100) }).toFormat('$0,0.00');
    return (Math.abs(value) === 0) ? "$0.00" : value > 0 ? formatted : `(${formatted.replace('-', '')})`;
};

export const PaymentTypeNames = {
    Cash: 'Cash',
    DebitCard: 'Debit Card',
    Check: 'Check',
    CertifiedFunds: 'Certified Funds',
};

export const PaymentTypeName = (type: string) => {
    switch (type) {
        case 'CASH':
            return PaymentTypeNames.Cash;
        case 'DEBIT':
            return PaymentTypeNames.DebitCard;
        case 'CHECK':
            return PaymentTypeNames.Check;
        case 'CERTIFIED_FUNDS':
            return PaymentTypeNames.CertifiedFunds;
        default:
            return '';
    }
};

export const PaymentFilterNames = {
    ReadyToCheckout: "Ready to Checkout",
    InProgress: "In Progress",
    Error: "Error",
    Completed: "Completed",
    Abandoned: "Abandoned"
}