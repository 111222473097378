/**
 * Boolean attributes on Horizon web components do not work as expected in React.
 * The attribute must be set as `undefined` in order for it to behave as `false`.
 * This convenience function will evaluate the provided condition and return `true`
 * if the condition is met, or `undefined` (i.e. `false`) if the condition is not met.
 * @param condition The condition for which the attribute should be `true`.
 */
export const HznBool = (condition: boolean): true | undefined =>  condition || undefined;

/**
 * Convert the string value of a HorizonCurrencyInput to a decimal.
 * @param value the raw input value
 * @returns a decimal representation of the value, or undefined if none provided.
 */
export const ResolveHznCurrency = (value: string | null | undefined): number | undefined => {
    // transform "$123,456.78" => "123456.78", or empty string if null/undefined.
    const decimalStr = (value ?? '').replace(/[\$,]/g, '');
    // transform "123456.78" => 123456.78, or undefined if empty string.
    return decimalStr === '' ? undefined : Number(decimalStr);
};
