/* eslint-disable no-bitwise */
import Claims from '@src/models/Claims';
import { jwtDecode } from 'jwt-decode';
import URI from 'urijs';
import { BROKER, OAUTH_CLIENT } from '../config';

export function authorizationUrl(nonce: string) {
    console.log(`Checking authorization URL with nonce, ${nonce}.`);

    const url = URI(BROKER.authorizeEndpoint)
        .addQuery('client_id', OAUTH_CLIENT.clientId)
        .addQuery('response_type', OAUTH_CLIENT.responseType)
        .addQuery('scope', OAUTH_CLIENT.scope)
        .addQuery('state', encodeURIComponent(window.location.pathname));
    if (OAUTH_CLIENT.prompt) {
        url.addQuery('prompt', OAUTH_CLIENT.prompt);
    }
    if (OAUTH_CLIENT.acrValues) {
        url.addQuery('acr_values', OAUTH_CLIENT.acrValues);
    }
    if (OAUTH_CLIENT.maxAge) {
        url.addQuery('max_age', OAUTH_CLIENT.maxAge);
    }
    return url;
}

export function logoutUrl(state: string) {
    console.log(`Getting logout URL with state, ${state}`);

    /* Removing addQuery from URL since we are not redirecting after SLO fire
    let url = URI(BROKER.logoutEndpoint)
    .addQuery('post_logout_redirect_uri', OAUTH_CLIENT.redirectUri); */
    const url = URI(BROKER.logoutEndpoint);
    return url;
}

export const handleTokenResponseData = (resp: { access_token: string; refresh_token: string; id_token: string }) => {
    sessionStorage.removeItem('com.unboundid.kmxpos.nonce');
    sessionStorage.setItem('com.unboundid.kmxpos.access_token', resp.access_token);
    sessionStorage.setItem('com.unboundid.kmxpos.id_token', resp.id_token);
    if (resp.refresh_token) {
        sessionStorage.setItem('com.unboundid.kmxpos.refresh_token', resp.refresh_token);
    }
    const claims = jwtDecode(resp.id_token) as Claims;
    sessionStorage.setItem('com.unboundid.kmxpos.profile', JSON.stringify(claims));
    return claims;
};
