import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { CertifiedFundsPaymentDetails, reset, selectPayment, setPaymentDetails, setProcessStep } from '@src/store/PaymentSlice';
import { Modal } from 'react-bootstrap';
import { ProcessPayment } from '../Common/ProcessPayment';
import { ConfirmCertifiedFundsAmount } from './ConfirmCertifiedFundsAmount';
import { CertifiedFundsPaymentDescription } from './CertifiedFundsPaymentDescription';
import { ScanCheck } from '../Common/ScanCheck';

/**
 * ProcessCertifiedFundsModal is a modal wizard for processing certified funds payments.
 * This component provides the modal shell, presents the modal content for
 * each wizard step, and orchestrates control flow for the steps.
 */
export const ProcessCertifiedFundsModal = ({ show }: { show: boolean }) => {
    const dispatch = useAppDispatch();
    const { step } = useAppSelector(selectPayment);

    return (
        <Modal centered size="sm" show={show}>
            {show && step === 'Start' && (
                // Confirm the check amount and number
                <ConfirmCertifiedFundsAmount
                    onContinue={details => {
                        dispatch(setPaymentDetails({ type: "CERTIFIED_FUNDS", checkNumber: details.checkNumber, checkAmount: details.checkAmount } as CertifiedFundsPaymentDetails));
                        dispatch(setProcessStep('CheckScan'));
                    }}
                    onCancel={() => dispatch(reset())}
                />
            )}
            {show && step === 'CheckScan' && (
                // Prompt the associate to scan the check
                <ScanCheck onContinue={() => dispatch(setProcessStep('Finish'))} onCancel={() => dispatch(reset())} >
                    <CertifiedFundsPaymentDescription />
                </ScanCheck>
            )}
            {show && step === 'Finish' && (
                // Submit the payment for processing
                <ProcessPayment onComplete={() => dispatch(reset())} onCancel={() => dispatch(reset())}>
                    <CertifiedFundsPaymentDescription />
                </ProcessPayment>
            )}
        </Modal>
    );
};
