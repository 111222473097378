import React from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import CheckoutStepper from './CheckoutStepper';
import { CartDetails } from '@src/components/CartDetails';
import { Payment } from './Payment/Payment';
import { useGetBasketQuery } from '@src/api/CheckoutApi';
import { TenderOrder } from '../TenderOrder/TenderOrder';
import { selectActiveStep } from '@src/store/CheckoutSlice';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { DocRemote } from '../DocRemote';
import { Suspense } from '@src/components/Suspense';

export const BasketCheckout = () => {
    const { basketId } = useParams();
    const activeStep = useAppSelector(selectActiveStep);

    const basket = useGetBasketQuery({ basketId });

    return (
        <Suspense suspended={basket.isLoading}>
            {basket.data && (
                <hzn-stack space="large">
                    <Header basketDetails={basket.data} />
                    <hzn-columns space="large" collapse-below="xlarge">
                        <hzn-column width="1/3">
                            <hzn-stack>
                                <CartDetails basket={basket.data} />
                                <CheckoutStepper basketId={basketId} />
                            </hzn-stack>
                        </hzn-column>
                        <hzn-column width="2/3">
                            {activeStep?.name === 'Payment' && (
                                <Payment basket={basket.data} loading={basket.isFetching} />
                            )}
                            {activeStep?.remoteUrl && (
                                <DocRemote url={activeStep.remoteUrl} basket={basket.data} />
                            )}
                            {activeStep?.name === 'Tender Order' && <TenderOrder basketId={basketId} />}
                        </hzn-column>
                    </hzn-columns>
                </hzn-stack>
            )}
        </Suspense>
    );
};
