import {HubConnectionBuilder} from '@microsoft/signalr';
import { OAUTH_PROXY } from "@src/config";

const buildSignalRConnection = (endpoint: string) => 
    new HubConnectionBuilder()
        .withUrl(`${OAUTH_PROXY.apiProxyUri}/checkout/${endpoint}`, {
            withCredentials: false,
            headers: {
                Authorization: `Bearer ${(sessionStorage.getItem('com.unboundid.kmxpos.access_token') ?? "").replace('Bearer', '').trim()}`,
                id_token: sessionStorage.getItem('com.unboundid.kmxpos.id_token'),
                refresh_token: sessionStorage.getItem('com.unboundid.kmxpos.refresh_token'),
            },
        })
        .build();

/**
 * 
 * @param basketId The basket ID for which to receive workflow notifications
 * @param workflowUpdateCallback A callback function when a workflow update notification is received.
 */
export const subscribeForWorkflowUpdateNotifications = (basketId: string, workflowUpdateCallback: () => void): void => {
    const connection = buildSignalRConnection("basket-workflows");

    // When workflow update message is received, call the provided callback function
    connection.on('onBasketWorkflowUpdated', () => {
        console.log(`Received workflow update notification`);
        workflowUpdateCallback();
    });

    connection.onclose((error?: Error) => {
        if (error)
            console.error(`SignalR connection closed with an errors`, error);
        else
            console.log(`Closed connection to SignalR`);
    });

    connection.start()
        .then(() => console.log('Connected to SignalR workflow hub'))
        .then(() => connection.send('RegisterAsync', basketId))
        .then(() => console.log(`Registered with SignalR hub for workflow updates`))
        .catch(error => console.error(`SignalR connection failed to start.`, error));
};

