import {
    BasketDto,
    OrderTenderSummaryDto,
    useCreateOrdersMutation,
    useGetBasketQuery,
    useGetOrderTenderSummaryQuery,
} from '@src/api/CheckoutApi';
import { Card, CardRow } from '@src/components/Card';
import { FlexColumn, FlexRow } from '@src/components/Flex';
import { HeadedContainerCard } from '@src/components/HeadedContainerCard';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { SummaryTotal, SummaryTotalProps } from '@src/components/SummaryTotal';
import { Suspense } from '@src/components/Suspense';
import { addToast } from '@src/components/toast/ToastSlice';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { usePageTitle } from '@src/hooks/usePageTitle';
import { AsCurrency } from '@src/utils/Formatting';
import { StepTitleHeader } from '../BasketCheckout/Payment/StepTitleHeader/StepTitleHeader';

interface TenderOrderDetailsProps {
    summary: OrderTenderSummaryDto;
}

const TenderOrderDetails = ({ summary }: TenderOrderDetailsProps) => {
    return (
        <HeadedContainerCard title="Appraisal Purchase Order">
            <div className="d-flex flex-column gap-3">
                <CardRow label="Vehicle" value={summary.vehicleName} />
                <hzn-divider />
                <CardRow label="Offer Amount" value={AsCurrency(summary.vehicleOfferValue)} />
                <hzn-divider />
                {summary.espCancellationValue > 0 && (
                    <>
                        <CardRow label="GAP Cancellation" value={AsCurrency(summary.gapCancellationValue)} />
                        <hzn-divider />
                    </>
                )}
                {summary.espCancellationValue > 0 && (
                    <>
                        <CardRow label="ESP Cancellation" value={AsCurrency(summary.espCancellationValue)} />
                        <hzn-divider />
                    </>
                )}
                {summary.payoffToLienholder > 0 && (
                    <>
                        <CardRow
                            label={`Payoff to ${summary.lienholderName}`}
                            // TODO: add lienholder name to DTO, adjust mock data layer
                            value={AsCurrency(summary.payoffToLienholder)}
                        />
                        <hzn-divider />
                    </>
                )}
                <CardRow
                    label="Customer equity"
                    value={
                        <hzn-text tone={summary.equityTotal < 0 ? 'critical' : 'body'}>
                            {AsCurrency(summary.equityTotal)}
                        </hzn-text>
                    }
                />
                <hzn-divider />
                {/* "Due to CarMax" is 0 if equity is >= 0 */}
                <CardRow
                    label={<hzn-text weight="bold">Due to {summary.equityTotal >= 0 ? 'Customer' : 'CarMax'}</hzn-text>}
                    className="pt-3"
                    value={<hzn-text weight="bold">{AsCurrency(Math.abs(summary.equityTotal))}</hzn-text>}
                />
            </div>
        </HeadedContainerCard>
    );
};

export interface TenderOrderProps {
    basketId: string;
}

export const TenderOrder = ({ basketId }: TenderOrderProps) => {
    usePageTitle('Tender Order');
    const dispatch = useAppDispatch();
    const basket = useGetBasketQuery({ basketId });
    const summary = useGetOrderTenderSummaryQuery({ basketId });
    const [tenderOrder, tenderOrderStatus] = useCreateOrdersMutation();

    // TODO: place redirect for tender order validation when it's done.

    const calculateTotalDueToday = (summary: OrderTenderSummaryDto): number => {
        const diff = summary.equityTotal + summary.processedPaymentsTotal;
        // if diff is negative, customer owes that much. otherwise, they owe nothing.
        return diff < 0 ? diff * -1 : 0;
    };

    const onTenderOrder = () =>
        tenderOrder({ orderTenderRequestDto: { basketId } })
            .unwrap()
            .then(() => dispatch(addToast({ tone: 'positive', message: 'Order tendered successfully.' })))
            .then(() => basket.refetch())
            .catch(err => dispatch(addToast({ tone: 'critical', message: `Order tender error: ${err}` })));

    return (
        <FlexColumn className="gap-4">
            <Card>
                <FlexColumn>
                    <StepTitleHeader
                        title={'Tender Order'}
                        status={basket.data.basketStatus == 'COMPLETED' ? 'Completed' : 'InProgress'}
                        loading={summary.isLoading}
                    />
                    {summary.isSuccess && summary.data != null && (
                        <>
                            <TenderOrderDetails summary={summary.data} />
                            <SummaryTotal summary={summary.data} />
                        </>
                    )}
                </FlexColumn>
            </Card>
            <FlexRow className="justify-content-end">
                <HorizonButton
                    variant="cta"
                    loading={tenderOrderStatus.isLoading}
                    disabled={basket.isFetching || basket.data.basketStatus == 'COMPLETED'}
                    onClick={onTenderOrder}
                >
                    Tender Order
                </HorizonButton>
            </FlexRow>
        </FlexColumn>
    );
};
