import React from 'react';
import { useLocation } from 'react-router-dom';

type GenericNotFoundProps = {
};

export const GenericNotFound = (props: GenericNotFoundProps) => {
      const location = useLocation();
      return (
          <div
              style={{
                  background: 'var(--hzn-color-background)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                  minHeight: '100vh',
              }}
          >
              <hzn-card
                  padding="large"
                  border-radius="base"
                  rounded-above="medium"
                  elevation="medium"
                  style={{
                      width: '90%',
                      marginTop: '24px'
                  }}
              >
                <hzn-stack space="medium">
                    <hzn-heading>Not Found</hzn-heading>
                    <hzn-text inline><i>Oops!</i> The content you were looking for could not be found.</hzn-text>
                    <hzn-button variant="primary" href="/checkout/baskets">
                        Return to Dashboard
                    </hzn-button>
                    <hzn-divider></hzn-divider>
                    <hzn-text inline variant="eyebrow">Technical Details</hzn-text>
                    <hzn-text variant="caption">HTTP 404. The provided route, {location.pathname}, was invalid.</hzn-text>
                </hzn-stack>
            </hzn-card>
          </div>
      );
};
