import React, { useEffect, useMemo } from 'react';

const resolveClientDeepLink = (location: Location) => {
    const host = location.host.toLowerCase();
    const path = location.pathname;
    var env = host.includes('dev') ? '-dev' : host.includes('qa') ? '-qa' : '';
    var appPath = `checkout/${path.replace('/gateway/', '')}`;
    return `carmaxpos${env}://${appPath}`;
};

const Logomark = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="73" viewBox="0 0 72 73" fill="none">
        <rect width="72" height="72" transform="translate(0 0.5)" fill="#FFD900" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.9608 35.0708C24.8881 36.4449 24.4367 36.8427 23.7671 36.8427C22.4656 36.8427 22.357 35.3611 22.357 33.3711C22.357 31.2739 22.4836 29.8994 23.822 29.8994C24.4543 29.8994 24.9968 30.1894 25.069 31.672H29.8066C29.7885 27.8391 27.0041 26.5 23.7133 26.5C19.3007 26.5 16.8782 29.2485 17.0047 33.317C17.0047 37.4583 19.1199 40.2421 23.6951 40.2421C26.9318 40.2421 29.8066 38.6697 29.8066 35.0708H24.9608ZM31.4058 39.7825H36.5049V31.9262C36.5049 30.9222 36.9471 30.521 37.431 30.521C37.9155 30.521 38.3155 30.9976 38.3155 31.8763V39.7825H43.4146V31.9262C43.4146 30.9222 43.8564 30.521 44.3415 30.521C44.8264 30.521 45.2256 30.9976 45.2256 31.8763V39.7825H50.3251V31.4C50.3251 28.0605 48.7869 26.8063 46.6593 26.8063C45.4575 26.8063 44.193 27.2831 43.3291 29.0907C42.6766 27.534 41.4972 26.8063 39.8753 26.8063C38.6747 26.8063 37.4098 27.2831 36.5463 29.0907H36.5049L36.3781 27.1833H31.4058V39.7825ZM31.5377 46.7368H41.7413V43.6543H31.5377V46.7368ZM44.6321 46.7368H54.8353V43.6543H44.6321V46.7368Z"
            fill="#053361"
        />
    </svg>
);


const Gateway = () => {
    // determine the POS client scheme and deep link path
    const url = useMemo(() => resolveClientDeepLink(window.location), []);

    useEffect(() => {
        // Attempt to load the POS client
        console.log(`Attempt to launch ${url} in Electron app`);
        window.location.replace(url);
    }, []);

    return (
        <div className="gateway pt-5 h-100">
            <hzn-stack space="xlarge" align-x="center">
                <div>
                    <Logomark />
                </div>
                <hzn-heading as="h1" size="large" align="center">
                    Opening Checkout App
                </hzn-heading>
                <hzn-card elevation="flat" border-radius="small" class="p-3 w-66">
                    <hzn-stack space="medium">
                        <hzn-text>You can close this window when the Checkout App has launched.</hzn-text>
                        <hzn-text>If the app doesn’t launch automatically:</hzn-text>
                        <hzn-text>1. Try again from a different workstation.</hzn-text>
                        <hzn-text>
                            2. Submit a{' '}
                            <hzn-text-link
                                href="https://carmax.service-now.com/css?id=emp_taxonomy_topic&topic_id=9801f4f01b4d211050abdbd9dc4bcb00"
                                external
                                target="_blank"
                                weight="bold"
                            >
                                Solution Center
                            </hzn-text-link>{' '}
                            ticket to request the <strong>Checkout App</strong> to be installed on this workstation.
                        </hzn-text>
                    </hzn-stack>
                </hzn-card>
                <hzn-text-link href={url} weight="bold" external>
                    RETRY OPENING CHECKOUT APP
                </hzn-text-link>
            </hzn-stack>
        </div>
    );
};

export default Gateway;
