import React, { useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { removeToast, selectToasts } from './ToastSlice';
// import './Toast.scss';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { ToastMessage } from '../../types';

const AutoHidingToast = ({ toast }: { toast: ToastMessage }) => {
    const dispatch = useAppDispatch();
    const [show, setShow] = useState(true);
    const onClose = () => {
        // hide the toast, allowing it to fade out
        setShow(false);
        // after 500ms remove the toast from the list.
        return new Promise(resolve => setTimeout(resolve, 500)).then(() => dispatch(removeToast(toast.id)));
    };

    return (
        <Toast key={toast.id} show={show} autohide delay={5000} onClose={onClose} className="mb-2">
            <Toast.Body className="p-0">
                <hzn-card elevation="small" border-radius="medium" padding="none">
                    <hzn-alert role='status' aria-label={toast.message} show tone={toast.tone} heading={toast.message} />
                </hzn-card>
            </Toast.Body>
        </Toast>
    );
};

export const ToastStack = () => {
    const toasts = useAppSelector(selectToasts);
    return (
        <ToastContainer position="top-end" className="position-fixed mt-5 me-1">
            {toasts.map(toast => (
                <AutoHidingToast key={toast.id} toast={toast} />
            ))}
        </ToastContainer>
    );
};
