import { FlexColumn } from '@src/components/Flex';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import React, {  } from 'react';
import { Modal } from 'react-bootstrap';

interface ScanCheckProps {
    onContinue: () => void;
    onCancel: () => void;
}

/**
 *  ScanCheck prompts the user to scan the check and handles the hardware interaction.
 *  TODO: Check scanning integration doesn't exist yet. For now just proceed to the next step.
 */
export const ScanCheck = (props: React.PropsWithChildren<ScanCheckProps>) => {
    const { payment } = useAppSelector(selectPayment);
    const description = payment.paymentType === 'CERTIFIED_FUNDS' ? 'certified funds ' : '';
    return (
        <>
            <Modal.Header>
                <hzn-heading size="xsmall">Processing payment</hzn-heading>
            </Modal.Header>
            <Modal.Body>
                <FlexColumn>
                    <hzn-text>Scan the {description}check before placing it in the slot of the cash register drawer.</hzn-text>
                    {props.children}
                </FlexColumn>
            </Modal.Body>
            <Modal.Footer>
                <FlexColumn className="w-100">
                    <HorizonButton variant="primary" onClick={props.onContinue}>
                        Scan Check
                    </HorizonButton>
                    <HorizonButton variant="tertiary" onClick={props.onCancel}>
                        Cancel
                    </HorizonButton>
                </FlexColumn>
            </Modal.Footer>
        </>
    );
};
