import React from 'react';
import { OAPErrorPresentation } from '../containers/OAuthCallback';
import { GenericModal } from "./GenericModal";

interface ErrorModalProps {
    error: OAPErrorPresentation;
    showModal: boolean;
}

export const OAuthErrorModal = (props: ErrorModalProps) => {
    return (
        <GenericModal
            title = {props.error.title}
            message = {props.error.message}
            alertHeading = {`Error Code ${props.error.statusNumber}`}
            alertMessage = {props.error.additionalDetails}
            footerText = {`Reference ID: ${props.error.requestId}`}
            tone = {props.error.tone}
            showCancel = {false}
            action = {props.error.action}
            actionButtonTitle = {props.error.actionLabel}
            showModal = {props.showModal}
        />
    );
};
