// Note: The contents of this file must exactly match the cashDrawerApi.ts file in
// the Electron app. We don't anticipate these APIs changing frequently, so we're
// opting with this approach rather than creating an NPM package that both the
// Desktop Electron app and this site references.

// Source
// https://github.com/CarMax-Internal/commerce-point-of-sale-desktop/blob/main/src/CarMax.PointOfSale.DesktopApp/src/common/cashDrawerApi.ts

export enum DrawerStatus {
    Error = 'Error',
    Offline = 'Offline',
    Closed = 'Closed',
    Open = 'Open',
}

export enum OpenDrawerStatus {
    Error = 'Error',
    Offline = 'Offline',
    Opened = 'Opened',
    AlreadyOpened = 'AlreadyOpened',
}

export type CashDrawerApi = {
    getCashDrawerStatusAsync: () => Promise<DrawerStatus>;
    openCashDrawerAsync: () => Promise<OpenDrawerStatus>;
};
