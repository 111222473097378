import { appInsights } from './appinsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Claims } from '../models/Claims';

export class Logger {
    public setUserContext(claims: Claims): void {
        if (appInsights) {
            appInsights.setAuthenticatedUserContext(claims.sub, undefined, true);
        }
    }

    public logException(error: Error): void {
        if (appInsights) {
            appInsights.trackException({
                error,
                severityLevel: SeverityLevel.Error,
            });
        }
    }

    public logError(message: string, properties: any = {}): void {
        this.logTrace(message, properties, 'Error');
    }

    public logInfo(message: string, properties: any = {}): void {
        this.logTrace(message, properties, 'Information');
    }

    public logVerbose(message: string, properties: any = {}): void {
        this.logTrace(message, properties, 'Verbose');
    }

    public logWarning(message: string, properties: any = {}): void {
        this.logTrace(message, properties, 'Warning');
    }

    public logTrace(
        message: string,
        properties: any = {},
        level: 'Verbose' | 'Information' | 'Warning' | 'Error'
    ): void {
        const severityLevel =
            level === 'Information'
                ? SeverityLevel.Information
                : level === 'Verbose'
                ? SeverityLevel.Verbose
                : level === 'Warning'
                ? SeverityLevel.Warning
                : SeverityLevel.Error;

        if (appInsights) {
            appInsights.trackTrace(
                {
                    message,
                    severityLevel,
                },
                properties
            );
        }
    }
}

export const logger = new Logger();
