import React from 'react';
import classNames from 'classnames';
import { FlexColumn } from '../Flex';
import './Stepper.scss';
import { WorkflowProgression, WorkflowProgressionStep } from '@src/api/CheckoutApi';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { setActiveStep } from '@src/store/CheckoutSlice';

export type StepDescriptorState = 'NOT_STARTED' | 'IN_PROGRESS' | 'PARTIALLY_COMPLETE' | 'COMPLETE';

export interface StepDescriptor {
    name: string;
    active?: boolean;
    disabled?: boolean;
    state?: StepDescriptorState;
    link?: string;
    remoteUrl?: string;
}

export interface Step extends StepDescriptor {
    children?: StepDescriptor[];
}

interface StepperProps {
    label: string;
    steps: Step[];
    active: Step;
}

// determine the step's status. If the step has children, use the furthest-along child step.
const resolveStatus = (step: Step): string => {
    if (step.disabled) {
        return 'Disabled';
    } else if (!step.children) {
        return step.state;
    } else if (step.children.every(c => c.state === 'COMPLETE')) {
        return 'Complete';
    } else if (step.children.some(c => c.state === 'COMPLETE' || c.state === 'PARTIALLY_COMPLETE')) {
        return 'PartiallyComplete';
    } else if (step.children.some(c => c.state === 'IN_PROGRESS')) {
        return 'InProgress';
    } else {
        return 'NotStarted';
    }
};

export const Stepper = ({ label, steps, active }: StepperProps) => {
    const dispatch = useAppDispatch();

    return (
        <hzn-card border-radius="medium" elevation="flat" role="region" padding="none" aria-label={label}>
            <FlexColumn className="stepper pt-2 pb-2 gap-1">
                {steps.map((step, stepIdx) => {
                    const isActive = step.name === active?.name;
                    return (
                        <div key={stepIdx}>
                            <div className={classNames('single-step', isActive ? 'active' : 'inactive')}>
                                <div className="d-flex flex-row gap-4 align-items-center">
                                    <div className={classNames('status', resolveStatus(step))}></div>
                                    <hzn-text
                                        tone={step.disabled ? 'disabled' : isActive ? 'info' : 'body'}
                                        weight={isActive ? 'bold' : 'base'}
                                        style={{ cursor: step.disabled ? 'default' : 'pointer' }}
                                        onClick={step.disabled ? undefined : () => dispatch(setActiveStep(step))}
                                    >
                                        {step.name}
                                    </hzn-text>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </FlexColumn>
        </hzn-card>
    );
};
