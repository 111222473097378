import { BasketDto, PaymentDirection, PaymentDto, PaymentStatus, PaymentType } from '@src/api/CheckoutApi';

export const getVehicleName = (basket: BasketDto): string => {
    const lineItems = basket.lineItems?.filter(item => item.lineItemType === 'APPRAISAL_ITEM');
    return lineItems.length > 0 ? lineItems[0].name : 'NOT FOUND';
};

export const getPartyId = (basket: BasketDto): string =>
    basket.lineItems[0].participants.find(p => p.roles.includes('SELLER')).id;

export const getTitleHolderName = (basket: BasketDto): string => 
    basket.lineItems[0].participants
    .filter(p => p.roles.includes('TITLE_HOLDER'))
    .map(p => p.name)
    .join(' and ');

const ActivePaymentStatuses: PaymentStatus[] = ['AUTHORIZED', 'NEW', 'PAID', 'PENDING', 'PROCESSING', 'SUBMITTED'];

const getPaymentsOfDirectionAndType = (basket: BasketDto, direction: PaymentDirection, statuses: PaymentStatus[]) =>
    (basket.payments ?? []).filter(p => p.direction == direction && statuses.includes(p.status));

const totalPayments = (payments: PaymentDto[]) => payments.reduce((acc, p) => acc + (p.amount ?? 0), 0);

export const getActivePayments = (basket: BasketDto) =>
    getPaymentsOfDirectionAndType(basket, 'INBOUND', ActivePaymentStatuses);

export const getUnprocessedPayments = (basket: BasketDto) => getPaymentsOfDirectionAndType(basket, 'INBOUND', ['NEW']);

export const getProcessedPaymentsTotal = (basket: BasketDto) =>
    totalPayments(getPaymentsOfDirectionAndType(basket, 'INBOUND', ['PAID']));

export const getTotalEquity = (basket:BasketDto) =>
    basket.lineItems.reduce((acc, curr) => acc + (curr.equity ?? 0), 0);

export const getNegativeEquityBalance = (basket: BasketDto): number => {
    // if the customer equity is positive, there is no balance.
    const totalEquity = getTotalEquity(basket);
    if (totalEquity >= 0) return 0;

    // total up all the inbound unprocessed payments (cash, debit, etc.)
    const sumOfUnprocessedPayments = totalPayments(getActivePayments(basket));

    // the customer equity will be negative, e.g. -5000.
    // the unprocessed payment sum will be positive, e.g. 2000
    // the balance should be positive. math.abs(-5000 + 2000) => 3000
    return Math.abs(getTotalEquity(basket) + sumOfUnprocessedPayments);
};
