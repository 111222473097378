import React from 'react';
import { FlexRow } from './Flex';

interface SuspenseProps {
    suspended: boolean;
    message?: string;
}

export const Suspense = (props: React.PropsWithChildren<SuspenseProps>) =>
    props.suspended ? (
        <FlexRow className='gap-2 justify-content-center'>
            <hzn-loading />
            <div>{props.message}</div>
        </FlexRow>
    ) : (
        <>{props.children}</>
    );
