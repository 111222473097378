import React, { useCallback, useRef } from 'react';
import { useEventListener } from '../../hooks/useEventListener';
import { updateTerm } from './BasketSearchSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { HznSearchInput } from '@horizon/search-input';
import { selectBasketSearchQuery } from './BasketSearchSlice';
import { useAppSelector } from '../../hooks/useAppSelector';


export const BasketSearchTerm = () => {
    const dispatch = useAppDispatch();
    const {term} = useAppSelector(selectBasketSearchQuery);
    const ref = useRef<HznSearchInput | undefined>(undefined);

    const onUpdateTerm = useCallback(
        (input: HznSearchInput) => {
            dispatch(updateTerm(input.value ?? undefined));
        },
        [dispatch]
    );
    //@ts-ignore non-standard 'search' event is part of Horizon spec
    useEventListener(ref, 'search', onUpdateTerm);
    //@ts-ignore non-standard 'clear' event is part of Horizon spec
    useEventListener(ref, 'clear', onUpdateTerm);
    useEventListener(ref, 'blur', onUpdateTerm);
    useEventListener(ref, 'keypress', (target, event) => {
        if (event.key === "Enter") {
            onUpdateTerm(target);
        }
    })

    return (
        <hzn-search-input
            ref={ref}
            value={term}
            interactive-icon
            clearable
            placeholder="Search carts by Customer Name or VIN"
        >
            <span className="visually-hidden">Search term</span>
        </hzn-search-input>
    );
};
