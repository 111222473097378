import React, { useCallback, useRef } from 'react';
import { useEventListener } from '../../hooks/useEventListener';
import { updatePaymentState } from './BasketSearchSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { HznSelect } from '@horizon/select';
import { selectBasketSearchQuery } from './BasketSearchSlice';
import { useAppSelector } from '../../hooks/useAppSelector';
import { PaymentFilterNames } from '@src/utils/Formatting';

export const BasketPaymentStateFilter = () => {
    const dispatch = useAppDispatch();
    const { paymentState } = useAppSelector(selectBasketSearchQuery);
    const ref = useRef<HznSelect | undefined>(undefined);

    const onUpdatePaymentState = useCallback(
        (select: HznSelect) => {
            const selectedPaymentState = select?.value === '' ? undefined : select?.value;
            dispatch(updatePaymentState(selectedPaymentState));
        },
        [dispatch]
    );

    //@ts-ignore non-standard 'search' event is part of Horizon spec
    useEventListener(ref, 'change', onUpdatePaymentState);
    //@ts-ignore non-standard 'clear' event is part of Horizon spec
    useEventListener(ref, 'clear', onUpdatePaymentState);

    return (
        <hzn-select ref={ref} placeholder="All active carts" clearable value={paymentState ?? ''}>
            <span className="visually-hidden">Cart status</span>
            <option value={PaymentFilterNames.ReadyToCheckout}>{PaymentFilterNames.ReadyToCheckout}</option>
            <option value={PaymentFilterNames.InProgress}>{PaymentFilterNames.InProgress}</option>
            <option value={PaymentFilterNames.Completed}>{PaymentFilterNames.Completed}</option>
            <option value={PaymentFilterNames.Error}>{PaymentFilterNames.Error}</option>
        </hzn-select>
    );
};
