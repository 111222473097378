import { BasketDto } from '@src/api/CheckoutApi';
import { FlexRow } from '@src/components/Flex';
import { CardReaderBadge } from '@src/components/Hardware/CardReaderBadge';
import { CashDrawerBadge } from '@src/components/Hardware/CashDrawerBadge';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Header = ({ basketDetails }: { basketDetails: BasketDto }) => {
    const { storeLocationId } = useParams();
    const navigate = useNavigate();

    return (
        <FlexRow className="w-100 align-items-center gap-2">
            <hzn-text weight="bold" as="div">
                <hzn-text-link
                    weight="base"
                    onClick={e => {
                        e.preventDefault();
                        navigate(`/checkout/${storeLocationId}`);
                    }}
                >
                    All Carts
                </hzn-text-link>{' '}
                / {basketDetails.basketOwnerName}'s Checkout
            </hzn-text>
            <div className="ms-auto">
                <CashDrawerBadge />
            </div>
            <div>
                <CardReaderBadge />
            </div>
        </FlexRow>
    );
};

export default Header;
