/* eslint-disable consistent-return */
// Customize parameters of the OAuth 2 request used to initiate the
// authentication process.

// Identifier passed for OAP request routing (see OAP utility.ping_request)
export const appIdentifierOAP = () => {
    if (allAPIMHostnames().includes(window.location.hostname)) {
        return 'desktopgateway';
    } else {
        return 'checkout';
    }
};

const standardDevHostnames = [
    'commerce-checkout-web-dev-east-site.azurewebsites.net',
    'commerce-checkout-web-dev-west-site.azurewebsites.net',
    'commerce-checkout-web-site-dev.trafficmanager.net'
];
const apimDevHostnames = [
    'apim-dev-east-oapdesktopgateway.azure-api.net',
    'apim-dev-west-oapdesktopgateway.azure-api.net'
];
export const devHostnames = () => {
    const joined = standardDevHostnames.concat(apimDevHostnames);
    return joined;
};

const standardQAHostnames = [
    'commerce-checkout-web-qa-east-site.azurewebsites.net',
    'commerce-checkout-web-qa-west-site.azurewebsites.net',
    'commerce-checkout-web-site-qa.trafficmanager.net'
];
const apimQAHostnames = [
    'apim-qa-east-oapdesktopgateway.azure-api.net',
    'apim-qa-west-oapdesktopgateway.azure-api.net'
];
export const qaHostnames = () => {
    const joined = standardQAHostnames.concat(apimQAHostnames);
    return joined;
};

const standardProdHostnames = [
    'commerce-checkout-web-prod-east-site.azurewebsites.net',
    'commerce-checkout-web-prod-west-site.azurewebsites.net',
    'commerce-checkout-web-site-prod.trafficmanager.net'
];
const apimProdHostnames = [
    'apim-prod-east-oapdesktopgateway.azure-api.net',
    'apim-prod-west-oapdesktopgateway.azure-api.net'
];
export const prodHostnames = () => {
    const joined = standardProdHostnames.concat(apimProdHostnames);
    return joined;
};

const allAPIMHostnames = () => {
    return [...apimProdHostnames, ...apimQAHostnames, ...apimDevHostnames];
};

// Job Code authorization settings; used for AuthzCheck.js
export const authorizedJobCodes = [
    'STAST',
    'BOASS',
    'BOALD',
    'K1445',
    'K1257',
    'NEBOM',
    'OFFMC',
    'BMITR',
    'K0850',
    'K0842',
    'K0851',
    'K0844',
    'K0865',
    'K0843',
    'K0866',
    'K1515',
];
export const managerJobCodes = ['NEBOM', 'OFFMC', 'BMITR', 'K0844', 'K0865', 'K0843', 'K0866', 'K1515'];
export const adminAccessCodes: string[] = [];

// FIXME: If we're hosting this locally, we can't rely on the hostname to tell us which is needed
// This is fixed for when routing back from OAP's Ping callback, but we still need to know where to route the login button initially
function handleEnvironment() {
    if (process.env.USE_MSW) {
        return 'mock';
    }

    const storageEnv = sessionStorage.getItem('com.unboundid.kmxpos.env');
    if (prodHostnames().includes(window.location.hostname) || storageEnv === 'prod') {
        return 'prod';
    }
    if (devHostnames().includes(window.location.hostname) || storageEnv === 'dev') {
        return 'dev';
    }
    if (qaHostnames().includes(window.location.hostname) || storageEnv === 'qa') {
        return 'qa';
    }
    // TODO TOPROD: once this goes to prod, default env should be prod
    return 'qa';
}

export const ENVIRONMENT = handleEnvironment();

function handleBaseProxyEnv() {
    switch (ENVIRONMENT) {
        case 'prod':
            return 'https://commerce-oauthproxy-service-prod.trafficmanager.net';
        case 'qa':
            return 'https://commerce-oauthproxy-service-qa.trafficmanager.net';
        case 'dev':
            return 'https://commerce-oauthproxy-service-dev.trafficmanager.net';
        case 'mock':
            return '';
    }
}

function handleBaseApiEnv() {
    switch (ENVIRONMENT) {
        case 'prod':
            return 'https://commerce-checkout-service-prod.trafficmanager.net/';
        case 'qa':
            return 'https://commerce-checkout-service-qa.trafficmanager.net/';
        case 'dev':
            return 'https://commerce-checkout-service-dev.trafficmanager.net/';
        case 'mock':
            return 'http://localhost:3553';
    }
}

function handleBasePingEnv() {
    switch (ENVIRONMENT) {
        case 'prod':
            return 'https://login.carmax.com';
        default:
            return 'https://loginqa.carmax.com';
    }
}

function handleOIDCClient() {
    console.log(`Requested OID Client with current hostname: ${window.location.hostname}`);
    switch (ENVIRONMENT) {
        case 'prod':
            if (apimProdHostnames.includes(window.location.hostname)) {
                return 'POS_App_Prod'
            } else {
                return 'POS_CHECKOUT_PROD';
            }
        case 'qa':
            if (apimQAHostnames.includes(window.location.hostname)) {
                return 'POS_App_Quality_Assurance'
            } else {
                return 'POS_CHECKOUT_QA';
            }
        case 'dev':
        default:
            if (apimDevHostnames.includes(window.location.hostname)) {
                return 'POS_App_Development'
            } else {
                return 'POS_CHECKOUT_DEV';
            }
    }
}

export const OAUTH_CLIENT = {
    // Unique ID for an OAuth2 Client configured with the Broker
    clientId: handleOIDCClient(),
    responseType: 'code',
    prompt: '',
    acrValues: '',
    maxAge: '', // FIXME: This should be 600 (10mins?), but temp removal for demo 3/23/23
    scope: 'openid profile email kmxidentity kmxroles',
};

export const OAUTH_PROXY = {
    authCodeUri: `${handleBaseProxyEnv()}/api/auth_code`,
    apiProxyUri: `${handleBaseProxyEnv()}/api/proxy`,
    apiUri: handleBaseApiEnv(),
    // fileProxyUri: `${handleBaseProxyEnv()} + '/file_proxy',
    // queueProxyUri: `${handleBaseProxyEnv()} + '/queue_proxy'
};

// Settings used when verifying an OpenID Connect ID token.
export const OIDC: {
    issuer: string;
    jwa: string;
    gracePeriod: number;
    debugmode?: boolean;
} = {
    // The auth server's issuer ID.
    issuer: handleBasePingEnv(),
    // The JWT signing algorithm.
    jwa: 'RS256',
    // A grace period in seconds for verifying time-based claims
    // (e.g., 'iat', 'exp').
    gracePeriod: 15,
    // Set below to output decrypted JWT for debugging purposes
    // debugmode: true
};

// Data Governance Broker service URLs.
export const BROKER = {
    authorizeEndpoint: `${handleBasePingEnv()}/as/authorization.oauth2`,
    logoutEndpoint: `${handleBasePingEnv()}/idp/startSLO.ping`,
    introspectEndpoint: `${handleBasePingEnv()}/as/token.oauth2`,
    jwksEndpoint: `${handleBasePingEnv()}/pf/JWKS`,
    meEndpoint: `${handleBasePingEnv()}/idp/userinfo.openid`,
};

// SCIM attribute paths. Change these if you're using a custom schema.
export const SCIM_SCHEMA = {
    username: 'userName',
    fullName: 'name.formatted',
    email: 'emails[primary eq true]',
    phone: 'phoneNumbers[primary eq true]',
    birthday: 'urn:pingidentity:schemas:sample:profile:1.0:birthDate',
};

// OpenID Connect claim names. Change these if you're using a
// custom schema.
export const OIDC_SCHEMA = {
    username: 'preferred_username',
    fullName: 'name',
    email: 'email',
};

// General application settings.
export const APP_SETTINGS = {
    rootUri: 'http://localhost:3000/',
    defaultTcBuildAgent: ['HCSTCAPPP32'],
};

export const PROXY_NAMES = {
    mock: 'mock',
    pathfinders_oauthproxy: 'pathfinders_oauthproxy',
    bon_apim: 'bon_apim',
}
