import '@horizon/icons/individual/hzn-plus';
import { Card } from '@src/components/Card';
import { StepTitleHeader, WorkflowStepStatus } from '@src/pages/BasketCheckout/Payment/StepTitleHeader/StepTitleHeader';
import React, { useMemo } from 'react';
import { usePageTitle } from '@src/hooks/usePageTitle';
import { BasketDto } from '@src/api/CheckoutApi';
import { NegativeEquity } from './NegativeEquity';
import { PositiveEquity } from './PositiveEquity';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow } from '@src/components/Flex';
import { getTotalEquity } from '@src/utils/BasketFns';

export interface PaymentProps {
    basket: BasketDto;
    loading?: boolean;
}

const resolveStatus = (equity: number, processedPaymentsAmount: number): WorkflowStepStatus =>
    equity >= 0 || processedPaymentsAmount === Math.abs(equity) // basket is positive equity, or all negative equity has been paid.
        ? 'Completed'
        : processedPaymentsAmount > 0 // some negative equity has been paid.
        ? 'PartiallyComplete'
        : 'InProgress'; // no negative equity has been paid.

export const Payment = ({ basket, loading }: PaymentProps) => {
    usePageTitle('Payment');
    const navigate = useNavigate();

    const totalEquity = useMemo(() => getTotalEquity(basket), [basket]);
    const status = resolveStatus(totalEquity, basket.customerProcessedPaymentAmount);

    return (
        <FlexColumn className="gap-4">
            <Card>
                <FlexColumn>
                    <StepTitleHeader title={'Payment'} status={status} loading={loading} />
                    {totalEquity < 0 && <NegativeEquity basket={basket} />}
                    {totalEquity >= 0 && <PositiveEquity basket={basket} />}
                </FlexColumn>
            </Card>
            <FlexRow className="justify-content-end">
                <HorizonButton variant="cta" disabled={status !== 'Completed'} onClick={() => navigate('tenderOrder')}>
                    Continue
                </HorizonButton>
            </FlexRow>
        </FlexColumn>
    );
};
