import React, { useMemo } from 'react';
import { Card, CardRow } from './Card';
import { AsCurrency } from '@src/utils/Formatting';
import { getNegativeEquityBalance, getTotalEquity, getVehicleName } from '@src/utils/BasketFns';
import { BasketDto } from '@src/api/CheckoutApi';

export const CartDetails = ({ basket }: { basket: BasketDto }) => {
    const totalEquity = useMemo(() => getTotalEquity(basket), [basket]);
    const balanceDue = useMemo(
        () => (totalEquity >= 0 ? AsCurrency(totalEquity) : AsCurrency(getNegativeEquityBalance(basket))),
        [basket]
    );

    return (
        <Card>
            <div className="d-flex flex-column gap-4">
                <CardRow
                    label={
                        <hzn-text weight="bold" size="small">
                            CHECKOUT
                        </hzn-text>
                    }
                    value={
                        // TODO: cart states aren't yet hashed out. figure out what this should look like
                        // after those requirements are established and codified in the API.
                        // see example of paymentStateBadge for UI guidance when this is available.
                        <hzn-badge label="In Progress" tone="info" />
                    }
                />
                <div>
                    <hzn-text weight="bold" size="large">
                        {basket.basketOwnerName}
                    </hzn-text>
                </div>
                <hzn-divider />
                <CardRow label="Appraisal purchase" value={getVehicleName(basket)} />
                <CardRow
                    label={`Total due to ${totalEquity <= 0 ? 'CarMax' : 'Customer'}`}
                    value={AsCurrency(Math.abs(totalEquity))}
                />
                <CardRow label="Processed payments" value={AsCurrency(basket.customerProcessedPaymentAmount)} />
                <CardRow
                    label={`Balance due to ${totalEquity <= 0 ? 'CarMax' : 'Customer'}`}
                    value={
                        <span className="stress">
                            {balanceDue}
                        </span>
                    }
                />
            </div>
        </Card>
    );
};
