import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    CardFlowApi,
    CardReaderConnectionStatus,
    CardReaderResult,
} from './electron/CardFlowApi';

interface HardwareCardFlowError {
    status: number;
    statusText: string;
    data: any;
}

export interface HardwareCardRequest {
    customerName: string;
    amountInUSD: number;
    requireSignature: boolean;
}

const cardFlow = (): CardFlowApi => {
    if (!window.CardFlow) throw 'Card Flow hardware interface not found.';
    return window.CardFlow;
};

const cardFlowApi = createApi({
    reducerPath: 'cardFlow',
    baseQuery: fakeBaseQuery<HardwareCardFlowError>(),
    endpoints: builder => ({
        /**
         * Check whether the electron app currently detects a connected and configured card reader.
         * If true, the card reader should be ready to process debit card payments.
         */
        hardwareConnectionStatus: builder.query<CardReaderConnectionStatus, any>({
            queryFn: async () => {
                try {
                    const isConnected = await cardFlow().getConnectionStatus();
                    return { data: isConnected };
                } catch (ex) {
                    return { error: { data: CardReaderConnectionStatus.Error, status: 0, statusText: ex } };
                }
            },
        }),
        /**
         * Initiate a debit card read. The electron app handles all user interaction.
         */
        hardwareSwipeCard: builder.mutation<CardReaderResult | undefined, any>({
            queryFn: async (queryArg: HardwareCardRequest) => {
                try {
                    const result = await cardFlow().requestCardSwipe(
                        queryArg.customerName,
                        queryArg.amountInUSD,
                        queryArg.requireSignature
                    );
                    return { data: result };
                } catch (ex) {
                    return { error: { data: undefined, status: 0, statusText: ex } };
                }
            },
        }),
    }),
});

export { cardFlowApi as CardFlowApi };
export const { useHardwareConnectionStatusQuery, useHardwareSwipeCardMutation } = cardFlowApi;
