import { CashPaymentDto } from '@src/api/CheckoutApi';
import { FlexRow } from '@src/components/Flex';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { CashPaymentDetails, selectPayment } from '@src/store/PaymentSlice';
import { AsCurrency } from '@src/utils/Formatting';
import React from 'react';

export const CashPaymentDescription = () => {
    const { mode, payment, paymentDetails } = useAppSelector(selectPayment);
    const cashPaymentDetails = paymentDetails as CashPaymentDetails;
    const cashPayment = payment as CashPaymentDto;

    return (
        <>
            <FlexRow className={'justify-content-between pt-3 ps-2 pe-2'}>
                <hzn-text tone="subdued">Payment method</hzn-text>
                <hzn-text>Cash</hzn-text>
            </FlexRow>
            <hzn-divider />
            {mode === 'Process' && (
                <>
                    {!cashPaymentDetails && (
                        <FlexRow className={'justify-content-between ps-2 pe-2'}>
                            <hzn-text tone="subdued">Amount</hzn-text>
                            <hzn-text>{AsCurrency(payment.amount)}</hzn-text>
                        </FlexRow>
                    )}
                    {cashPaymentDetails && (
                        <>
                            <FlexRow className={'justify-content-between ps-2 pe-2'}>
                                <hzn-text tone="subdued">Amount collected from customer</hzn-text>
                                <hzn-text>{AsCurrency(cashPaymentDetails.cashCollected)}</hzn-text>
                            </FlexRow>
                            <hzn-divider />
                            <FlexRow className={'justify-content-between ps-2 pe-2'}>
                                <hzn-text tone="subdued">Amount applied towards balance</hzn-text>
                                <hzn-text>{AsCurrency(payment.amount)}</hzn-text>
                            </FlexRow>
                            <hzn-divider />
                            <FlexRow className={'justify-content-between ps-2 pe-2'}>
                                <hzn-text tone="subdued">Change due</hzn-text>
                                <span className="stress">
                                    {AsCurrency(cashPaymentDetails.cashCollected - payment.amount)}
                                </span>
                            </FlexRow>
                        </>
                    )}
                </>
            )}
            {mode === 'Void' && (
                <>
                    <FlexRow className={'justify-content-between ps-2 pe-2'}>
                        <hzn-text tone="subdued">Amount</hzn-text>
                        <hzn-text>{AsCurrency(cashPayment.cashCollected)}</hzn-text>
                    </FlexRow>
                    <hzn-divider />
                    <FlexRow className={'justify-content-between ps-2 pe-2'}>
                        <hzn-text tone="subdued">Amount applied towards balance</hzn-text>
                        <hzn-text>{AsCurrency(cashPayment.amount)}</hzn-text>
                    </FlexRow>
                    <hzn-divider />
                    <FlexRow className={'justify-content-between ps-2 pe-2'}>
                        <hzn-text tone="subdued">Change given</hzn-text>
                        <hzn-text>{AsCurrency(cashPayment.cashCollected - cashPayment.amount)}</hzn-text>
                    </FlexRow>
                    <hzn-divider />
                    <FlexRow className={'justify-content-between ps-2 pe-2'}>
                        <hzn-text tone="subdued">Refund amount due</hzn-text>
                        <span className='stress'>{AsCurrency(cashPayment.amount)}</span>
                    </FlexRow>
                </>
            )}
        </>
    );
};
