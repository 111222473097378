import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store/store';
import { ToastMessage, Tone } from '../../types';

interface ToastState {
    toasts: ToastMessage[]
}

const initialState : ToastState = {
    toasts: []
}

export const ToastSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        addToast: (state, action: PayloadAction<{tone:Tone, message:string}>) => {
            state.toasts.push({id: crypto.randomUUID(), ...action.payload});
        },
        removeToast: (state, action: PayloadAction<string>) => {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload)
        }
    },
});

export const { addToast, removeToast } = ToastSlice.actions;

export const selectToasts = (state: RootState) => state.toast.toasts;

export default ToastSlice.reducer;
