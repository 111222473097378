import { HznAlert, HznAlertTone } from '@horizon/alert';
import { HznBool } from '@src/utils/Horizon';
import React, { useRef } from 'react';
import { useEventListener } from '@src/hooks/useEventListener';

interface AlertProps  {
    tone: HznAlertTone;
    heading: string;
    'sub-heading'?: string;
    actionText?: string;
    show: boolean;
    elevated?: boolean;
    className?: string
    onDismiss?: () => void;
    onAction?: () => void;
}

const Noop = () => {};

export const HorizonAlert = (props: AlertProps) => {
    const ref = useRef<HznAlert>(undefined);

    //@ts-ignore dismiss is a synthetic event emitted by hzn-alert
    useEventListener(ref, 'dismiss', props.onDismiss ?? Noop);

    //@ts-ignore action is a synthetic event emitted by hzn-alert
    useEventListener(ref, 'action', props.onAction ?? Noop);

    return (
        <hzn-alert
            ref={ref}
            class={props.className}
            role="status"
            aria-label={props.heading}
            show={HznBool(props.show)}
            tone={props.tone}
            heading={props.heading}
            sub-heading={props['sub-heading']}
            action-text={props.actionText}
            elevated={HznBool(props.elevated ?? false)}
            dismissible={HznBool(!!props.onDismiss)}
        />
    );
};
