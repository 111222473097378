import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { SystemInteractionAPI } from './electron/SystemInteractionApi';

const systemInteraction = (): SystemInteractionAPI => {
    if (!window.SystemInteraction) throw 'System Interaction interface not found.';
    return window.SystemInteraction;
};

const systemInteractionApi = createApi({
    reducerPath: 'systemInteraction',
    baseQuery: fakeBaseQuery(),
    endpoints: builder => ({
        /**
         * Get the name of the workstation running the Electron app host
         */
        systemInteractionGetComputerName: builder.query<string, any>({
            queryFn: async () => {
                try {
                    const computerName = await systemInteraction().getComputerNameAsync();
                    return { data: computerName };
                } catch (ex) {
                    return { error: { data: "", status: 0, statusText: ex } };
                }
            },
        }),
    }),
});

export { systemInteractionApi as SystemInteractionApi };
export const { useSystemInteractionGetComputerNameQuery } = systemInteractionApi;
