// AuthzCheck
import { adminAccessCodes, authorizedJobCodes, managerJobCodes } from '../config';
import { Claims } from '../models/Claims';

// canAccessApp
/*
 * Conditions:
 * - Job Code matches on authorized list; OR
 *  - roles includes "KMX-AD-G-AZURE-CommerceService*"
 *      OR in dev mode (provided app isn't a production build);
 * - AND token is still valid (validate with OAP validate_token())
 *      IF NODE_ENV !== "production"
 */
export function canAccessApp(claims: Claims) {
    const inDevMode = claims['kmx.roles'].includes('DEVMODE') && process.env.NODE_ENV !== 'production';

    return (
        inDevMode ||
        authorizedJobCodes.includes(claims['kmx.jobcode']) ||
        claims['kmx.roles'].some(role => role.toUpperCase().includes('KMX-AD-G-AZURE-COMMERCESERVICE'))
    );
}

// isManager
/*
 * Conditions:
 * - Job Code matches that of a Manager; AND
 * - Management Level contains 'Manager'
 */
export function isManager(claims: Claims) {
    return managerJobCodes.includes(claims['kmx.jobcode']) && claims['kmx.managementlevel'].includes('Manager');
}

// canAccessAdmin
/*
 * - Job Code matches that of a Business Office Manager or Location General Manager - XF;
 *      OR in dev mode (provided app isn't a production build)
 */
export function canAccessAdmin(claims: Claims) {
    const inDevMode = claims['kmx.roles'].includes('DEVMODE') && process.env.NODE_ENV !== 'production';

    return adminAccessCodes.includes(claims['kmx.jobcode']) || inDevMode;
}
