import { FlexRow } from '@src/components/Flex';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import { AsCurrency } from '@src/utils/Formatting';
import React from 'react';

export const DebitPaymentDescription = () => {
    const { payment } = useAppSelector(selectPayment);
    return (
        <>
            <FlexRow className={'justify-content-between pt-3 ps-2 pe-2'}>
                <hzn-text tone="subdued">Payment method</hzn-text>
                <hzn-text>Debit Card</hzn-text>
            </FlexRow>
            <hzn-divider />
            <FlexRow className={'justify-content-between ps-2 pe-2'}>
                <hzn-text tone="subdued">Amount</hzn-text>
                <hzn-text>{AsCurrency(payment.amount)}</hzn-text>
            </FlexRow>
        </>
    );
};
