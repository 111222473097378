import { FlexColumn } from '@src/components/Flex';
import { CurrencyInput } from '@src/components/horizon/CurrencyInput';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import Input, { InputHandle } from '@src/components/horizon/Input';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CertifiedFundsPaymentDescription } from './CertifiedFundsPaymentDescription';

interface ConfirmCertifiedFundsAmountProps {
    onContinue: ({ checkAmount, checkNumber }: { checkAmount: number; checkNumber: string }) => void;
    onCancel: () => void;
}

/**
 * ConfirmCashAmount asks the BOA to enter the amount of cash provided by the customer for a cash payment.
 * This will be used to calculate any necessary change.
 */
export const ConfirmCertifiedFundsAmount = (props: ConfirmCertifiedFundsAmountProps) => {
    const { payment } = useAppSelector(selectPayment);
    const currencyRef = useRef<InputHandle>();
    const checkNumberRef = useRef<InputHandle>();
    const [checkAmount, setCheckAmount] = useState<number | undefined>(undefined);
    const [checkNumber, setCheckNumber] = useState<string | undefined>(undefined);

    const onConfirm = () => {
        if (!currencyRef.current.validate() || !checkNumberRef.current.validate()) return;
        props.onContinue({ checkAmount, checkNumber });
    };

    return (
        <>
            <Modal.Header>
                <hzn-heading size="xsmall">Confirm amount to be processed.</hzn-heading>
            </Modal.Header>
            <Modal.Body>
                <FlexColumn>
                    <hzn-text>Confirm the amount of the certified funds check.</hzn-text>
                    <CertifiedFundsPaymentDescription />
                    <hzn-divider />
                    <CurrencyInput
                        ref={currencyRef}
                        className="ps-2 pe-2"
                        required
                        min={payment.amount}
                        onValueChanged={setCheckAmount} // no-op. We just need to validate the amount is correct.
                        errorMessages={{
                            valueMissing: 'A valid dollar amount is required',
                            rangeUnderflow: `Cannot be less than the amount to be processed.`,
                        }}
                    >
                        Amount of certified funds check
                    </CurrencyInput>
                    <hzn-divider />
                    <Input
                        ref={checkNumberRef}
                        className="ps-2 pe-2"
                        label="Check number"
                        required
                        onValueChanged={setCheckNumber}
                        errorMessages={{
                            valueMissing: 'A check number is required',
                        }}
                    />
                </FlexColumn>
            </Modal.Body>
            <Modal.Footer>
                <FlexColumn className="w-100">
                    <HorizonButton variant="primary" onClick={onConfirm}>
                        Confirm Amount
                    </HorizonButton>
                    <HorizonButton variant="tertiary" onClick={props.onCancel}>
                        Cancel
                    </HorizonButton>
                </FlexColumn>
            </Modal.Footer>
        </>
    );
};
