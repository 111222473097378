import { FlexColumn } from '@src/components/Flex';
import { CurrencyInput } from '@src/components/horizon/CurrencyInput';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { InputHandle } from '@src/components/horizon/Input';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CashPaymentDescription } from './CashPaymentDescription';

interface ConfirmCashAmountProps {
    OnContinue: (collectedAmount: number) => void;
    onCancel: () => void;
}

/**
 * ConfirmCashAmount asks the BOA to enter the amount of cash provided by the customer for a cash payment.
 * This will be used to calculate any necessary change.
 */
export const ConfirmCashAmount = (props: ConfirmCashAmountProps) => {
    const { payment } = useAppSelector(selectPayment);
    const currencyRef = useRef<InputHandle>();
    const [collected, setCollected] = useState<number | undefined>(undefined);

    const onConfirm = () => {
        if (!currencyRef.current.validate()) return;
        props.OnContinue(collected);
    };

    return (
        <>
            <Modal.Header>
                <hzn-heading size="xsmall">Confirm amount to be processed</hzn-heading>
            </Modal.Header>
            <Modal.Body>
                <FlexColumn>
                    <hzn-text>Confirm the cash amount collected from the customer.</hzn-text>
                    <CashPaymentDescription />
                    <hzn-divider />
                    <CurrencyInput
                        ref={currencyRef}
                        className="ps-2 pe-2"
                        required
                        min={payment.amount}
                        onValueChanged={setCollected}
                        errorMessages={{
                            valueMissing: 'A valid dollar amount is required',
                            rangeUnderflow: 'Cannot be less that the amount to be processed.',
                        }}
                    >
                        Amount collected from customer
                    </CurrencyInput>
                </FlexColumn>
            </Modal.Body>
            <Modal.Footer>
                <FlexColumn className="w-100">
                    <HorizonButton variant="primary" onClick={onConfirm}>
                        Confirm Amount
                    </HorizonButton>
                    <HorizonButton variant="tertiary" onClick={props.onCancel}>
                        Cancel
                    </HorizonButton>
                </FlexColumn>
            </Modal.Footer>
        </>
    );
};
