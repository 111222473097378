import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { DebitPaymentDetails, reset, selectPayment, setPaymentDetails, setProcessStep } from '@src/store/PaymentSlice';
import { Modal } from 'react-bootstrap';
import { ReceiveCardData } from './RecieveCardData';
import { ProcessPayment } from '../Common/ProcessPayment';
import { DebitPaymentDescription } from './DebitPaymentDescription';

/**
 * ProcessDebitModal is a modal wizard for processing debit card payments.
 * This component provides the modal shell, presents the modal content for
 * each wizard step, and orchestrates control flow for the steps.
 */
export const ProcessDebitModal = ({ show }: { show: boolean }) => {
    const dispatch = useAppDispatch();
    const { step } = useAppSelector(selectPayment);

    return (
        <Modal centered size="sm" show={show}>
            {show && step === 'Start' && (
                // Initiate a card swipe and handle the resulting swipe data or error
                <ReceiveCardData
                    onReceived={data => {
                        dispatch(setPaymentDetails({ type: "DEBIT", debitCardPresentPaymentDetails: data } as DebitPaymentDetails));
                        dispatch(setProcessStep('Finish'));
                    }}
                    onCancel={() => dispatch(reset())}
                />
            )}
            {show && step === 'Finish' && (
                // Submit the payment for processing
                <ProcessPayment onComplete={() => dispatch(reset())} onCancel={() => dispatch(reset())} >
                    <DebitPaymentDescription />
                </ProcessPayment>
            )}
        </Modal>
    );
};
