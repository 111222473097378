import React, { useRef } from 'react';
import { reset } from './BasketSearchSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useEventListener } from '../../hooks/useEventListener';
import { HznText } from '@horizon/text';
import { NoResults } from '../../assets/NoResults';
import { HorizonTextNav } from '../../components/horizon/HorizonTextNav';

export const NoResultsAlert = ({ clearable = false }: { clearable?: boolean }) => {
    const dispatch = useAppDispatch();

    const ref = useRef<HznText | undefined>(undefined);

    useEventListener(ref, 'click', () => dispatch(reset()));

    return (
        <div className="d-flex flex-row align-items-center justify-content-center gap-3">
            <div>
                <NoResults />
            </div>
            <div className="d-flex flex-column gap-3">
                <div>
                    <hzn-text variant="callout">No matches found.</hzn-text>
                </div>
                {clearable && (
                    <div>
                        <HorizonTextNav size="large" onClick={() => dispatch(reset())}>
                            Clear search criteria.
                        </HorizonTextNav>
                    </div>
                )}
            </div>
        </div>
    );
};
