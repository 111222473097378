import React from 'react';
import { CardRow } from '../../../../components/Card';
import { AsCurrency, PaymentTypeName } from '@src/utils/Formatting';
import { HorizonButton } from '../../../../components/horizon/HorizonButton';
import { PaymentDto } from '@src/api/CheckoutApi';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectCardReaderStatus, selectCashDrawerStatus } from '@src/store/HardwareSlice';
import { setPayment } from '@src/store/PaymentSlice';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { FlexColumn, FlexRow } from '@src/components/Flex';

export interface PaymentCardProps {
    payment: PaymentDto;
    basketIsBalanced: boolean;
}

export const PaymentReadCard = ({ payment, basketIsBalanced }: PaymentCardProps) => {
    const dispatch = useAppDispatch();
    const cardReaderStatus = useAppSelector(selectCardReaderStatus);
    const cashDrawerStatus = useAppSelector(selectCashDrawerStatus);
    
    // in order to process a payment:
    // 1. the negative equity balance must be accounted for
    // 2. a cash drawer must be assigned to this workstations
    // 3. if this is a debit payment the card reader hardware must be functional
    // note: we don't explicitly check for functional cash drawer hardware because BOAs/BOMs can manually open the cash drawer with a key.
    const canProcessPayment = basketIsBalanced && !!cashDrawerStatus.assigned && (payment.paymentType !== "DEBIT" || !!cardReaderStatus.ready)

    return ( <>
        <FlexColumn className="gap-2">
            <CardRow label="Amount" value={<span className="stress">{AsCurrency(payment.amount)}</span>}
            />
            {payment.paymentType === 'CASH' && !!payment.cashCollected && (
                <CardRow label="Change given" value={<span className="stress">{AsCurrency(payment.cashCollected - payment.amount)}</span>} />
            )}
            {payment.paymentType === 'DEBIT' && !!payment.cardSuffix && (
                <CardRow label="Card Number" value={`...${payment.cardSuffix}`} />
            )}
            {(payment.paymentType === 'CHECK' || payment.paymentType === 'CERTIFIED_FUNDS') && !!payment.checkNumber && (
                <CardRow label="Check Number" value={payment.checkNumber} />
            )}
        </FlexColumn>
        {payment.status == 'NEW' && (
            <FlexRow>
                <HorizonButton
                    compact
                    variant="primary"
                    onClick={() => dispatch(setPayment({payment, mode: "Process"}))}
                    disabled={!canProcessPayment}
                >
                    Process Payment
                </HorizonButton>
            </FlexRow>
        )
        }
        {(payment.status == 'SUBMITTED' || payment.status == 'PAID') && (
            <FlexRow>
                <HorizonButton 
                    compact 
                    variant="secondary" 
                    tone="critical" 
                    onClick={() => {dispatch(setPayment({payment, mode: "Void"}))}}
                >
                    Void Payment
                </HorizonButton>
            </FlexRow>
        )}
    </>);
};
